import React, { useEffect, useState } from 'react'
import {
  Input,
  Select,
  Button,
  Form,
  Flex,
  notification, Modal
} from 'antd';

import ValidateMacAddress from "../../components/ValidateMacAddress";

import { REQUESTS } from '../../api/requests';

import useStore from '../../hooks/use-store';

import { HOST } from '../../config';

import checkSchedule from '../../utils/checkSchedule';

import dateFormat from '../../utils/dateFormat';
import PageTemplate from '../../components/PageTemplate';
import PageDescription from './components/PageDescription';
import useUpdateProfile from '../../hooks/use-update-profile';
import Text from '../../components/Text';
import TEXTS from '../../config/resellerText';

const { confirm } = Modal;

export default function Activation() {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [appsList] = useStore("apps");
  const [packages, setPackages] = useState([]);
  const [mac, setMac] = useState('');
  const [info, setInfo] = useState(false);
  const [profile, refreshProfile] = useUpdateProfile();
  const [lang] = useStore('language');

  const activate = (body) => {
    REQUESTS.DEVICES_ACTIVATION(
      body,
      (result) => {
        const { timestamp, application_name } = result
        let time = checkSchedule(timestamp) !== "lifetime" ? dateFormat(timestamp) : <Text>Lifetime</Text>
        setLoading(false);
        form.resetFields();
        form.setFieldValue('package_id', packages[0]?.id);
        refreshProfile();
        notification.success({
          message: TEXTS["Success"][lang],
          description: <div>
            {TEXTS["This"][lang]} {mac} {TEXTS["has been activated with"][lang]} <strong>{time}</strong> {TEXTS["on the"][lang]}
            <strong> {application_name}</strong>
          </div>
        })
        setMac('');
      },
      (error) => {
        setLoading(false)

        notification.error({
          message: "Error",
          description: error
        })
      }
    )
  }

  const onFinish = (values) => {
    const { remark, package_id, app_id } = values;

    setLoading(true)

    const body = {
      package_id,
      app_id,
      mac,
    }

    if (remark.length) {
      body.remark = remark;
    }

    REQUESTS.CHECK_MAC(
      { app_id, mac },
      (data) => {
        setLoading(false);

        if (data.message) {
          const { payed, activation_expired, } = data.message

          let shchedule = checkSchedule(activation_expired)
          const appName = appsList.find(app => app.id == app_id)?.name;

          if (payed) {
            if (shchedule === "yearly") {
              confirm({
                title: `${TEXTS['Activate Account'][lang]} - ${appName}`,
                icon: null,
                content: <div>
                  <div>
                    {TEXTS["Mac already activated until"][lang]} {dateFormat(activation_expired)},
                  </div>
                  <div>
                    {TEXTS["Do you still want to activate and extend expiry period?"][lang]}
                  </div>
                </div>,
                onOk() {
                  activate(body);
                },
                styles: {
                  content: {
                    padding: '0px',
                  },
                  body: {
                    padding: '20px',
                    backgroundColor: '#f0f2f5',
                    borderRadius: '8px',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  },
                  footer: {
                    textAlign: 'center',
                  }
                },
                okText: TEXTS["Yes"][lang],
                cancelText: TEXTS["No"][lang],
                footer: (_, { OkBtn, CancelBtn }) => (
                  <Flex justify='center'>
                    <CancelBtn />
                    <OkBtn />
                  </Flex>
                ),
              });
            } else {
              setLoading(false);

              notification.error({
                message: TEXTS["Error"][lang],
                description: TEXTS["This device is already lifetime activated"][lang]
              })
            }
          } else {
            activate(body)
          }
        }
      },
      (err) => {
        setLoading(false);
        notification.error({
          message: "Error",
          description: err
        })
      })
  }

  const getPackages = () => {
    REQUESTS.PACKAGES(
      { query: JSON.stringify({ pagination: false }) },
      (result) => {
        setPackages(result);
        form.setFieldValue('package_id', result[0]?.id);
      },
      (error) => {
        setPackages([]);
      }
    )
  }

  useEffect(() => {
    if (mac.length <= 17) {
      form.setFieldsValue({ mac });
    }
  }, [mac])

  useEffect(() => {
    getPackages();
  }, [])

  return (
    <PageTemplate title={<Text>Activation</Text>} setInfo={setInfo}>
      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ maxWidth: "500px" }}
          initialValues={{
            package_id: "",
            remark: "",
            app_id: ""
          }}
        >
          <Form.Item
            label={<Text>Select App</Text>}
            name="app_id"
            rules={[{
              required: true,
              message: <Text>Please select an app!</Text>
            }]}
          >
            <Select
              options={appsList.map(({ id, name, logo }) => ({
                value: id,
                label: <Flex align="center">
                  <img src={`${HOST}${logo}`} alt={name} style={{ width: 29, height: 28, marginRight: 8 }} />
                  <span>{name}</span>
                </Flex>
              }))}
              style={{ width: '100%', }}
            />
          </Form.Item>

          <Form.Item
            name="package_id"
            label={<Text>Select Package</Text>}
            rules={[{
              required: true,
              message: <Text>Please select package!</Text>
            }]}
          >
            <Select
              options={packages.map((p) => {
                return {
                  label: p.duration == "year" ? `1-${TEXTS["Year"][lang]}` : <Text>Lifetime</Text>,
                  value: p.id
                }
              })}
            />
          </Form.Item>
          <Form.Item
            name="mac"
            label={<Text>Mac Address</Text>}
            rules={[{
              required: true,
              message: <Text>Please enter Mac address!</Text>
            },
            ]}
          >
            <ValidateMacAddress
              value={mac}
              setValue={setMac}
              onChange={(e) => form.setFieldsValue({ mac: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            name="remark"
            label={<Text>Remark</Text>}
            rules={[
              {
                max: 255,
                message: <Text>Remark must be at least 255 characters</Text>
              }
            ]}
          >
            <Input.TextArea style={{ resize: "none" }} />
          </Form.Item>
          <Form.Item>
            <Flex justify="center">
              <Button
                type='primary'
                htmlType='submit'
                loading={loading}
              >
                <Text>Activate</Text>
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </div>
      <PageDescription
        open={info}
        setOpen={setInfo}
      />

    </PageTemplate >
  )
}
