
import InfoModal from '../../../components/InfoModal';

import Text from '../../../components/Text';

export default function PageDescription({ open, setOpen }) {
    return <InfoModal
        open={open}
        handleCancel={() => setOpen(false)}
        title={<Text>Chart statistics</Text>}
        text={
            <div>
                <strong><Text>Today`s activated devices</Text></strong><br />
                <Text>The card shows the total count of of activated devices.</Text><br /><br />
                <strong><Text>Total Activated Devices</Text> </strong><br />
                <Text>The card shows the total number of activated devices from registered day.</Text><br /><br />
                <strong><Text>Resellers</Text></strong><br />
                <Text>Total count of subresellers registered that belongs to  you.</Text><br /><br />
                <strong><Text>Total Credit Share</Text></strong><br />
                <Text>The final count of credits that parent reseller shares with his subresellers.</Text><br /><br />

                <strong><Text>Devices count by App</Text></strong><br />
                <Text>The Pie chart shows the total number of registered devices in each app.</Text><br /><br />
                <strong>App count</strong><br />
                <Text>The bar chart shows the App names by decresing count of registered devices on them.</Text><br /><br />
                <Text>The map shows the devices count by country</Text>
            </div>
        }

    />
}
