import InfoModal from '../../../components/InfoModal';
import Text from '../../../components/Text';

export default function PageDescription({ open, setOpen }) {
    return (
        <InfoModal
            title={<Text>Credits History</Text>}
            open={open}
            handleCancel={() => setOpen(false)}
            text={<>
                <Text>In the Credit history is seen the given and taken credits by a reseller to another subreseller.</Text><br />
                <Text>Are seen the credits given or taken from admin to the reseller.</Text><br />
                <Text>In the Credit counts is seen left credits after every action and the action is described in the description field with date of action.</Text>
            </>}
        />
    )
}
