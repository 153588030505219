import { createSlice } from '@reduxjs/toolkit'

export const apps = createSlice({
  name: 'apps',
  initialState: {
    value: []
  },
    reducers: {
      setApps: (state, action) => {
        state.value = action.payload
      }
  }
})

export const { setApps } = apps.actions

export default apps.reducer