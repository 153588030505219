import React, { useEffect, useState } from 'react'
import { Table, Button, Input, Flex, Tooltip, Dropdown } from 'antd';

import {
  SearchOutlined,
  PlusOutlined,
  EllipsisOutlined, EditOutlined
} from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import dateFormat from '../../utils/dateFormat';

import useStore from '../../hooks/use-store';

import ValidateMacAddress from "../../components/ValidateMacAddress"

import { HOST } from '../../config';

import ICONS from '../../config/icons';
import Text from '../../components/Text';
import dateFilterDropdown from '../../utils/dateFilterDropdown';

import PageTemplate from "../../components/PageTemplate"
import PageDescription from './components/PageDescription';
import ExportCSVModal from '../../components/ExportCSVModal';
import useResize from '../../hooks/use-resize';
import PlaylistDrawer from './components/PlaylistDrawer';
import PlaylistActionsDrawer from './components/PlaylistsActionsDrawer';
import TEXTS from '../../config/resellerText';

export default function Devices() {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [mac, setMac] = useState("");
  const [expiredDate, setExpiredDate] = useState("");
  const [date, setDate] = useState("");
  const [sort, setSort] = useState(["createdAt", "DESC"]);
  const [filter, setFilter] = useState({});

  const [info, setInfo] = useState(false);

  const [current, setCurrent] = useState(null);
  const [openPlaylistDrawer, setOpenPlaylistDrawer] = useState(false);
  const [openPlaylistActions, setOpenPlaylistActions] = useState(false);

  const [openExportModal, setOpenExportModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [exportDate, setExportDate] = useState("");

  const [apps] = useStore("apps");

  const [lang] = useStore("language");

  const resize = useResize()

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      render: (text, record, index) => {
        return (currentPage - 1) * limit + index + 1
      }
    },
    {
      title: 'Mac',
      dataIndex: 'mac',
      key: 'mac',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return <ValidateMacAddress value={mac} setValue={setMac} />
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
    },
    {
      title: <Text>Activated By</Text>,
      dataIndex: 'reseller_email',
      key: 'reseller_email',
      render: (text, record) => record.reseller_email ? record.reseller_email : '-',
    },
    {
      title: <Text>Remark</Text>,
      dataIndex: 'remark',
      key: 'remark',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Tooltip placement="topLeft" title={record?.remark}>
          {record?.remark}
        </Tooltip>
      ),
    },
    {
      title: <Text>App Name</Text>,
      dataIndex: 'app_name',
      key: 'app_name',
      align: "center",
      width: 200,
      sorter: true,
      render: (text, record) => <Flex align='center' gap={5} style={{ width: 200 }}>
        <img src={record.logo} style={{ width: 29, height: 28 }} />
        <p>{record.app_name}</p>
      </Flex>,
      filters: apps.map(app => ({ text: app.name, value: app.id })),
      filterMultiple: false,
    },
    {
      title: <Text>Used Credits</Text>,
      dataIndex: 'used_credits',
      key: 'used_credits',
      sorter: true
    },
    {
      title: <Text>Activated Date</Text>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) => record.createdAt ? dateFormat(record.createdAt) : '-',
      sorter: true,
      ...dateFilterDropdown(setDate)
    },
    {
      title: <Text>Expired Date</Text>,
      dataIndex: 'activation_expired',
      key: 'activation_expired',
      render: (text, record) => record.activation_expired ? dateFormat(record.activation_expired) : '-',
      sorter: true,
      ...dateFilterDropdown(setExpiredDate)
    },
    {
      title: <Text>Action</Text>,
      key: 'action',
      align: "center",
      render: (text, record) => (
        <>
          <Dropdown
            menu={{
              items: [
                {
                  key: "Playlists",
                  label: <Flex align='center' gap={5}>
                    {ICONS.PLAYLISTS} <Text>Playlists</Text>
                  </Flex>,
                  onClick: () => {
                    setCurrent(record);
                    setOpenPlaylistDrawer(true);
                  }
                }
              ]
            }}
            placement="bottom"
          >
            <EllipsisOutlined style={{ cursor: "pointer" }} />
          </Dropdown>
        </>
      ),
    },
  ];

  const getDevices = () => {
    setLoading(true);

    let params = {
      limit: limit,
      page: currentPage,
      sort,
    }

    if (search?.length || mac?.length) {
      params['search'] = {}
      if (search?.length) {
        params['search'] = {
          reseller_email: search,
          mac: search
        }
      }
      if (mac?.length) {
        params['search'] = {
          mac,
        }
      }
    }

    const dates = [
      { value: "activation_expired", date: expiredDate },
      { value: "createdAt", date },
    ]

    for (let i = 0; i < dates.length; i++) {
      if (dates[i].date?.length && dates[i].date[0] && dates[i].date[1]) {
        params['between'] = {
          [dates[i].value]: {
            from: dates[i].date[0] + " 00:00",
            to: dates[i].date[1] + " 23:59"
          }
        }
      }
    }

    if (filter["app_name"] || filter["status"]) {
      params.filter = {}

      if (filter["app_name"]) {
        params.filter.app_id = filter["app_name"][0]
      }

      if (filter["status"]) {
        params.filter.status = filter["status"][0]
      }
    }

    REQUESTS.DEVICES(
      { query: JSON.stringify(params) },
      (data) => {
        try {
          const newData = [...data.rows];
          setTotal(data.total)
          setLoading(false);

          for (let i = 0; i < newData.length; i++) {
            for (let j = 0; j < apps.length; j++) {
              if (newData[i].app_id == apps[j].id) {
                newData[i].app_name = apps[j].name
                newData[i].logo = HOST + apps[j].logo;
                continue;
              }
            }
          }

          setDataSource(newData);

        } catch (e) {
          console.error(e);
          setLoading(false)
        }
      },
      (err) => {
        setLoading(false)
      })
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setFilter(filters)
    setCurrentPage(pagination.current)
    setLimit(pagination.pageSize)
    if (sorter.field && sorter.order) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    } else {
      setSort(["createdAt", "DESC"])
    }
  }


  const getExportData = () => {
    const query = {
      pagination: false,
    };

    if (exportDate && exportDate[0] && exportDate[1]) {
      query.between = {
        ["createdAt"]: {
          from: exportDate[0] + " 00:00",
          to: exportDate[1] + " 23:59"
        }
      }
    }

    REQUESTS.DEVICES(
      { query: JSON.stringify(query) },
      (data) => {
        setLoading(false);
        setCsvData(data)
      },
      (err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (exportDate.length) getExportData()
  }, [exportDate])

  useEffect(() => {
    let interval = setTimeout(() => {
      getDevices()
    }, 1000);

    return () => {
      clearTimeout(interval)
    }
  }, [expiredDate, date, search, mac, sort, currentPage, limit, filter])

  return (
    <PageTemplate
      title={<Text>Devices</Text>}
      setInfo={setInfo}
    >
      <Flex justify='space-between' gap={10}>
        <Input
          prefix={<SearchOutlined />}
          placeholder={TEXTS['Search'][lang]}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: 300, marginBottom: 10 }}
        />
        {/* <Button onClick={() => setOpenExportModal(true)}>Export to CSV</Button> */}
      </Flex>

      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        bordered
        scroll={{ x: 1000 }}
        size={resize <= 900 ? "small" : "large"}
        footer={() => total > 0 && <strong><Text>Total</Text>: {total}</strong>}
      />
      <PageDescription
        open={info}
        setOpen={setInfo}
      />
      <PlaylistDrawer
        current={current}
        open={openPlaylistDrawer}
        onClose={() => setOpenPlaylistDrawer(null)}
      />
      <PlaylistActionsDrawer
        current={current}
        open={openPlaylistActions}
        onClose={() => setOpenPlaylistActions(null)}
      />
      {/* <ExportCSVModal
        title="Devices"
        open={openExportModal}
        setDate={setExportDate}
        data={csvData}
        handleCancel={() => {
          setOpenExportModal(false);
        }}
      /> */}
    </PageTemplate>
  )
}
