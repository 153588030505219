import React from 'react'

import classNames from 'classnames';

import styles from "./styles/Message.module.scss";

export default function Message({
    message
}) {
    return (
        <div className={classNames(
            { [styles["message-close"]]: !message.text },
            { [styles["message-open"]]: message.text })}>
            <span
                className={classNames(
                    { [styles["error-text"]]: !message.type },
                    { [styles["success-text"]]: message.type })}
            >
                {message.text}
            </span>
        </div>
    )
}
