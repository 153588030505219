import React from 'react'

export default function checkSchedule(expiredDate) {
    let expiredActivationDate = new Date(expiredDate).getTime();

    let currentDate = new Date().getTime();

    let timeDifference = expiredActivationDate - currentDate;

    let yearDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24 * 365));

    let schedule = yearDifference > 10 ? 'lifetime' : 'yearly';

    return schedule;

}
