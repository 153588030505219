import { Modal, DatePicker, Button, Form } from 'antd';

import CSVDownload from './CSVDownload';

import styles from "./styles/csv.module.scss";

const { RangePicker } = DatePicker;

export default function ExportCSVModal({
    open,
    handleCancel,
    title,
    setDate,
    data = [],
    date
}) {
    const [form] = Form.useForm();


    return (
        <Modal
            open={open}
            onCancel={handleCancel}
            footer={null}
            destroyOnClose={true}
        >
            <div className={styles['export-modal-content']}>
                <h2>{title}</h2>
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={() => { }}
                >
                    <Form.Item
                        name="date"
                        label="Select Date"
                        rules={[{ required: true, message: 'Please select date!' }]}

                    >
                        <RangePicker
                            onChange={(date, dateString) => {
                                setDate(dateString)
                            }}
                        />
                    </Form.Item>
                </Form>
                <div className={styles['buttons']}>
                    <Button onClick={handleCancel}>
                        Cancel
                    </Button>
                    <CSVDownload data={data} />
                </div>
            </div>
        </Modal>
    )
}
