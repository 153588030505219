import React, { useEffect, useRef } from 'react';

import { Flex, Button } from 'antd';

import Text from '../../components/Text';

import ICONS from '../../config/icons';

import { REQUESTS } from '../../api/requests';

import useStore from '../../hooks/use-store';

import useUpdateProfile from '../../hooks/use-update-profile';

import styles from '../styles/Profile.module.scss';

export default function Profile() {
    const [store, setStore] = useStore();

    const [profile, refreshProfile] = useUpdateProfile();

    const refreshRef = useRef();

    const getApps = () => {

        let query = {
            pagination: false,
        };

        REQUESTS.APPS({ query: JSON.stringify(query) },
            (data) => {
                setStore({ type: "apps/setApps", payload: data })
            }, (err) => {
                console.error(err);
            });
    }

    const refresh = (e) => {
        if (refreshRef?.current?.classList.contains(styles["click"]) ||
            refreshRef?.current?.classList.contains(styles["done"])) return;

        refreshProfile()

        refreshRef?.current?.classList.add(styles["click"]);

        if (refreshRef?.current?.classList.contains(styles["click"])) {
            setTimeout(() => {

                refreshRef?.current?.classList.remove(styles["click"])
                refreshRef?.current?.classList.add(styles["done"])

                setTimeout(() => {

                    refreshRef?.current?.classList.remove(styles["done"])

                }, 2000);

            }, 500);
        }
    }

    useEffect(() => {
        getApps();
        refreshProfile()
    }, [])

    return (<Flex gap={10}>
        <Flex align="center" gap={10}>
            <strong style={{ lineHeight: 1.2 }}>
                <Text>Credit Point</Text> {profile?.credits}
            </strong>
            <Button className={styles['header-refresh-button']} ref={refreshRef} onClick={refresh}>
                <div className={styles["refresh-icon"]}>
                    <span className={styles["check-icon"]}>
                        {ICONS.CHECK_ICON}
                    </span>
                    <span className={styles["reload-icon"]}>
                        {ICONS.RELOAD}
                    </span>
                </div>
                <Text>Refresh</Text>
            </Button>
        </Flex>
    </Flex>)
}
