import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CloseOutlined,
  LogoutOutlined,
  InfoCircleOutlined, HddOutlined
} from '@ant-design/icons';

import { Layout, Menu, theme, Button, Flex, Modal, Divider } from 'antd';

import classNames from 'classnames';

import { REQUESTS } from '../api/requests';

import ICONS from '../config/icons';
import TEXTS from '../config/resellerText';

import useStore from '../hooks/use-store';

import LanguageDropdown from '../components/languageDropdown';
import Text from '../components/Text';

import Profile from './components/Profile';

import styles from "./styles/layout.module.scss";
import NotificationModal from '../components/NotificationModal';

const { Header, Content, Sider } = Layout;

const version = require('../version.json').version;

export default function LayoutComponent() {
  const location = useLocation();

  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(window.innerWidth < 900);

  const [width, setWidth] = useState(window.innerWidth);

  const [modalOpen, setModalOpen] = useState(false);

  const [apiVersion, setApiVersion] = useState('');

  const [notifications, setNotifications] = useState(null);
  const [current, setCurrent] = useState(null);
  const [open, setOpen] = useState(false);

  const [profile] = useStore("profile");

  const [language] = useStore("language");

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const getVersion = () => {
    REQUESTS.GET_VERSION(setApiVersion, () => { })
  }

  const getNotification = () => {

    if (!profile?.email) return;

    const query = {
      email: profile.email,
    }

    if (profile.is_partner) {
      query.status = "partner"
    } else if (profile.parent_id) {
      query.status = "subreseller"
    } else if (!profile.is_reseller) {
      query.status = "reseller"
    }

    const callback = (data) => {
      const unViewed = [];

      data.forEach((item) => {
        item.viewed = item?.reseller_notifications?.[0]?.viewed;
        if (!item.viewed) {
          unViewed.push(item);
        }
      })

      if (unViewed.length > 0) {
        setNotifications(unViewed);
      }
    }

    REQUESTS.NOTIFICATIONS.GET(query, callback)
  }

  const logout = () => {

    if (modalOpen) return;

    setModalOpen(true);

    if (width < 900) {
      setCollapsed(true)
    }

    Modal.confirm({
      content: TEXTS["Do you want to logout?"][language],
      onOk() {
        localStorage.clear();
        navigate("/");
        setModalOpen(false);
      },
      onCancel() {
        setModalOpen(false);
      },
      okText: TEXTS["OK"][language],
      cancelText: TEXTS["Cancel"][language],
      forceRender: true,
      okButtonProps: {
        type: 'primary',
        style: {
          backgroundColor: '#429229',
          borderColor: '#429229',
          hover: {
          },
        }
      },
      cancelButtonProps: {
        style: {
          borderColor: '#429229',
          color: '#429229',
          hover: {
            color: '#fff',
            backgroundColor: '#429229',
          }
        }
      },
    });
  }

  useEffect(() => {

    setWidth(window.innerWidth);

    getVersion()

    if (window.innerWidth < 900) {
      setCollapsed(true)
    }

    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);

      if (window.innerWidth < 900) {
        setCollapsed(true)
      } else {
        setCollapsed(false)
      }
    })
  }, []);

  useEffect(() => {
    getNotification();
  }, [profile]);


  useEffect(() => {
    if (notifications && notifications.length > 0) {
      setOpen(true);
      setCurrent(notifications[0]);
    }
  }, [notifications, open])

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: 'auto',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          transition: collapsed ? "none" : "all 0.2s",
          transform: width < 900 ?
            !collapsed ? "translateX(0px)" :
              "translateX(-100%)" :
            "translateX(0px)",
          zIndex: width < 900 ? 999999 : 0,
        }}>
        <Flex justify='space-between' gap={10} vertical style={{ minHeight: "100%" }}>
          <div>
            {width < 900 &&
              <CloseOutlined
                onClick={() => setCollapsed(true)}
                className={styles['close-icon']}
              />}
            <div className="demo-logo" style={{ width: collapsed ? 59 : 107 }} onClick={() => {
              navigate("/reseller/dashboard")
            }}>
              {collapsed ? ICONS.LOGO_ICON : ICONS.LOGO}
            </div>
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["/reseller/dashboard"]}
              selectedKeys={[location.pathname]}
              items={[
                {
                  key: "/reseller/dashboard",
                  icon: ICONS.DASHBOARD,
                  label: <Link to={"/reseller/dashboard"}><Text>Dashboard</Text></Link>,
                },
                {
                  key: "/reseller/dashboard/resellers",
                  icon: ICONS.RESELLERS,
                  label: <Link to={"/reseller/dashboard/resellers"}><Text>Resellers</Text></Link>,
                },
                {
                  key: "/reseller/dashboard/devices",
                  icon: ICONS.DEVICES,
                  label: <Link to={"/reseller/dashboard/devices"}><Text>Devices</Text></Link>,
                },
                {
                  key: "/reseller/dashboard/activation",
                  icon: ICONS.ACTIVATION,
                  label: <Link to={"/reseller/dashboard/activation"}><Text>Activation</Text></Link>,
                },
                // {
                //   key: "/dashboard/multi-app-activation",
                //   icon: <FileSyncOutlined />,
                //   label: <Link to={"/dashboard/multi-app-activation"}>Multi App Activation</Link>,
                // },
                {
                  key: "/reseller/dashboard/check_mac",
                  icon: ICONS.CHECK_MAC,
                  label: <Link to={"/reseller/dashboard/check_mac"}><Text>Check Mac</Text></Link>,
                },
                {
                  key: "/reseller/dashboard/credits_history",
                  icon: ICONS.CREDITS_HISTORY,
                  label: <Link to={"/reseller/dashboard/credits_history"}><Text>Credits History</Text></Link>,
                },
                {
                  key: "/reseller/dashboard/apps_links",
                  icon: <InfoCircleOutlined style={{ fontSize: 20 }} />,
                  label: <Link to={"/reseller/dashboard/apps_links"}><Text>Apps & Links</Text></Link>,
                },
                profile && profile.is_partner && {
                  key: "/reseller/dashboard/free-dns",
                  icon: <HddOutlined style={{ fontSize: 20 }} />,
                  label: <Link to={"/reseller/dashboard/free-dns"}><Text>Free DNS</Text></Link>,
                },
              ]}
              onClick={() => {
                if (width < 900) {
                  setCollapsed(true)
                }
              }}
              style={{
                background: "transparent",
                marginTop: 35,
                transition: "none !important",
              }}
            />
            <Divider />
            <div
              className={classNames(
                styles['menu-item-profile'],
                { [styles['active']]: location.pathname == "/reseller/dashboard/profile" })}
              style={{
                paddingLeft: profile?.name?.length >= 18 ? 10 : 24,
                justifyContent: collapsed ? "center" : "flex-start"
              }}
              onClick={() => {
                if (width < 900) {
                  setCollapsed(true)
                }
                navigate("/reseller/dashboard/profile")
              }}>
              {collapsed ?
                ICONS.PROFILE :
                <div>
                  {ICONS.PROFILE} <div className={styles['name']}>
                    {profile?.name || "Profile"}
                  </div>
                </div>}
            </div>
          </div>
          <div>
            <div className={styles["sider-bottom"]} >
              <div
                className={styles["note"]}
                style={{ flexDirection: collapsed ? "column" : "row" }}
              >
                <Link to="https://t.me/multiplayerapps" target='_blank'>
                  {ICONS.TELEGRAM}
                </Link>
                {/* <Link to="https://wa.me/34627571553" target='_blank'>
                  {ICONS.WHATS_UP}
                </Link> */}
              </div>
              <div onClick={logout} className={styles['logout-button']}>
                <LogoutOutlined className={styles['logout-icon']} />
                {!collapsed ? <Text>Logout</Text> : ""}
              </div>
              <div style={{ display: collapsed ? "none" : "block" }}>
                <Text>Reseller</Text> v{version} API v{apiVersion}
              </div>
            </div>
          </div>
        </Flex>
      </Sider>
      <Layout
        style={{
          marginLeft: width < 900 ? 0 :
            collapsed ? 80 : 200,
          transition: "all 0.2s",
          minHeight: "100vh"
        }}>
        <Header
          style={{
            padding: width < 390 ? "0 5px" : "0 25px 0 10px",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: "#ffffff",
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)",
            backdropFilter: "blur(10px)",
            zIndex: 99,
            position: "fixed",
            top: 0,
            right: 0,
            left: width < 900 ? 0 : collapsed ? 80 : 200,
            transition: "all 0.2s",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
          />
          <Flex gap={10} align='center'>
            <Link
              to="/reseller/dashboard/notifications"
              className={styles['notification-icon']}
            >
              <div className={styles['bell-icon']}>{ICONS.BELL}</div>
            </Link>
            <Profile />
            <LanguageDropdown />
          </Flex>
        </Header>
        <Content
          style={{
            margin: width < 900 ? '88px 5px 0' : '88px 16px 0',
            padding: width < 900 ? 10 : 24,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
          id='ant-layout-content'
        >
          <Outlet />
        </Content>
      </Layout>
      <NotificationModal
        open={open}
        setOpen={() => {
          setOpen(false);
          setNotifications(notifications.slice(1))
        }}
        notification={current}
      />
    </Layout >
  );
};

