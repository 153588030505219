
import React, { useEffect, useState } from 'react'

import { Table, Input, Flex, Button } from 'antd';
import {
  SearchOutlined
} from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import dateFormat from '../../utils/dateFormat';

import PageTemplate from "../../components/PageTemplate";

import PageDescription from './components/PageDescription';

import ExportCSVModal from '../../components/ExportCSVModal';

import filterDropdown from '../../utils/FilterDropdown';
import useResize from '../../hooks/use-resize';
import Text from '../../components/Text';
import TEXTS from '../../config/resellerText';
import useStore from '../../hooks/use-store';

export default function CreditsHistory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(["createdAt", "DESC"]);
  const [date, setDate] = useState("");
  const [search, setSearch] = useState("");
  const [info, setInfo] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [exportDate, setExportDate] = useState("");
  const [lang] = useStore("language")
  const resize = useResize();

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => {
        return (currentPage - 1) * limit + index + 1
      }
    },
    {
      title: <Text>Action</Text>,
      dataIndex: 'action',
      sorter: true,
      render: (text, record) => {
        let action = record.action ? record.action.replaceAll("_", " ") : "-"
        action = action[0].toUpperCase() + action.slice(1).toLowerCase()
        return action
      }
    },
    {
      title: <Text>Credits Count</Text>,
      dataIndex: 'count',
      sorter: true,
      render: (text, record) => record.count ? `${record.count} (${record?.result}) ` : ""
    },
    {
      title: <Text>Description</Text>,
      dataIndex: 'description',
      sorter: true,
    },
    {
      title: <Text>Date</Text>,
      dataIndex: 'createdAt',
      align: "center",
      sorter: true,
      ...filterDropdown(setDate),
      render: (text, record) => record.createdAt ? dateFormat(record.createdAt) : "-"
    },
  ];

  const getCreditsHistory = () => {
    setLoading(true);

    const params = {
      limit,
      page: currentPage,
      sort,
    }

    if (search?.length) {
      params.search = {
        action: search,
        count: search,
        result: search,
        description: search
      }
    }

    if (date?.length && date[0] && date[1]) {
      params.between = {
        "createdAt": {
          from: date[0] + " 00:00",
          to: date[1] + " 23:59"
        }
      }
    }

    REQUESTS.CREDIT_HISTORY({ query: JSON.stringify(params) }, (data) => {
      setDataSource(data.rows)
      setTotal(data.total)
      setLoading(false)
    }, (err) => {
      setLoading(false)
    })
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current)
    setLimit(pagination.pageSize)
    if (sorter.field && sorter.order) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    } else {
      setSort(["createdAt", "DESC"])
    }
  }

  const getExportData = () => {
    const query = {
      pagination: false,
    };

    if (exportDate[0] && exportDate[1]) {
      query.between = {
        ["createdAt"]: {
          from: exportDate[0] + " 00:00",
          to: exportDate[1] + " 23:59"
        }
      }
    }
    REQUESTS.CREDIT_HISTORY(
      { query: JSON.stringify(query) },
      (data) => {
        setLoading(false);

        // if (data.length == 0) setCsvData("No Data")
        setCsvData(data)
      },
      (err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (Array.isArray(exportDate) && exportDate[0] && exportDate[1]) getExportData()
  }, [exportDate])

  useEffect(() => {
    let interval = setTimeout(() => {
      getCreditsHistory()
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, [currentPage, limit, sort, date, search])
  return (
    <PageTemplate title={<Text>Credits History</Text>} setInfo={setInfo}>
      <Flex justify='space-between' gap={10}>
        <Input
          placeholder={TEXTS["Search"][lang]}
          prefix={<SearchOutlined />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: 300, marginBottom: 10 }}
        />
        {/* <Button onClick={() => setOpenExportModal(true)}>Export to CSV</Button> */}
      </Flex>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        bordered
        size={resize <= 900 ? 'small' : "large"}
        scroll={{ x: 1000 }}
        footer={() => total > 0 && <strong><Text>Total</Text>: {total}</strong>}
      />
      <PageDescription open={info} setOpen={setInfo} />
      {/* <ExportCSVModal
        title="Credits History"
        open={openExportModal}
        setDate={setExportDate}
        date={exportDate}
        data={csvData}
        handleCancel={() => {
          setOpenExportModal(false);
          setExportDate("");
        }}
      /> */}
    </PageTemplate>
  )
}
