import React, { useEffect, useState } from 'react'

import { Divider, Select, Button, Flex, Form, notification } from 'antd';

import { REQUESTS } from '../../api/requests';

import ValidateMacAddress from '../../components/ValidateMacAddress'

import CheckMacModal from './components/CheckMacModal';

import useStore from '../../hooks/use-store';

import { HOST } from '../../config';

import PageTemplate from "../../components/PageTemplate"
import PageDescription from './components/PageDescription';
import Text from '../../components/Text';

export default function CheckMac() {

  const [mac, setMac] = useState('');

  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [info, setInfo] = useState(false);

  const [apps] = useStore("apps")

  const [form] = Form.useForm()

  const onFinish = (values) => {
    const { app_id } = values;

    const query = {
      app_id,
      mac,
    }

    setLoading(true);

    REQUESTS.CHECK_MAC(
      query,
      (data) => {
        setLoading(false);
        if (data.message) {
          setOpenModal(data.message)
        } else {
          setOpenModal(data)
        }
      },
      (err) => {
        setLoading(false);
        notification.error({
          message: "Error",
          description: err
        })
      })
  }

  useEffect(() => {
    if (mac.length <= 17) {
      form.setFieldsValue({ mac });
    }
  }, [mac])

  useEffect(() => {
    if (!openModal) {
      form.resetFields();
    }
  }, [openModal])

  return (<PageTemplate title={<Text>Check Mac</Text>} setInfo={setInfo}>
    <Form
      form={form}
      style={{ maxWidth: 500 }}
      layout='vertical'
      onFinish={onFinish}
    >
      <Form.Item
        label={<Text>Select App</Text>}
        name="app_id"
        rules={[{
          required: true,
          message: <Text>Please select an app!</Text>
        }]}
      >
        <Select
          options={apps.map(({ id, name, logo }) => ({
            value: id,
            label: <Flex align="center">
              <img src={`${HOST}${logo}`} alt={name} style={{ width: 29, height: 28, marginRight: 8 }} />
              <span>{name}</span>
            </Flex>
          }))}
          style={{ width: '100%', }}
        />
      </Form.Item>
      <Form.Item
        name="mac"
        label={<Text>Mac Address</Text>}
        rules={[{
          required: true,
          message: <Text>Please enter Mac address!</Text>
        },
        ]}
      >
        <ValidateMacAddress
          value={mac}
          setValue={setMac}
          onChange={(e) => form.setFieldsValue({ mac: e.target.value })}
        />
      </Form.Item>
      <Form.Item>
        <Flex justify='center'>
          <Button
            type='primary'
            loading={loading}
            htmlType='submit'
          >
            <Text>Check</Text>
          </Button>
        </Flex>
      </Form.Item>
    </Form>
    <CheckMacModal
      selectedApp={form.getFieldValue('app_id')}
      openModal={openModal}
      onClose={() => {
        setMac('')
        setOpenModal(false)
      }
      }
    />
    <PageDescription open={info} setOpen={setInfo} />
  </PageTemplate>
  )
}
