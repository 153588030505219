import React from 'react'

import InfoModal from "../../../components/InfoModal";

import { Alert, Divider } from 'antd';

import Text from '../../../components/Text';
import styles from "../index.module.scss";

export default function PageDescription({ open, setOpen }) {

    return (
        <InfoModal
            title={<Text>Resellers</Text>}
            open={open}
            handleCancel={() => setOpen(false)}
            text={<>
                <Text>Reseller can see the subresellers`s list by email and name.</Text><br />
                <Text>Search is done by name and email address.</Text><br />
                <Text>Expire date shows the date till when the subreseller account is active.</Text><br />
                <Text>Credit Points are the total active credits that subreseller has.</Text><br />
                <Text>The activated devices shows the total number of activated devices during the whole time by that subreseller.</Text><br />
                <Text>Created date is the date when the subreseller has been created.</Text><br />
                <Text>Status field shows the subreseller status as active or expired.</Text><br />
                <Text>From Actions reseller can Add and Take credits to/from subresellers.</Text><br />
                <Text>Reseller can Edit subreseller info from Actions by changing the name and Expire date only.</Text><br />
                <Text>By Add reseller reseller can add subreseller by generating a password with special password generator and all the fields here are required.</Text><br /><br />
                <Alert
                    message={<><strong style={{ color: "red" }}><Text>NOTE</Text>:</strong> <Text>Reseller can change his password from his panel or super admin can change it.</Text></>}
                    type="warning"
                />
                <Divider />
                <h3><Text>How to Add a Playlist?</Text></h3>
                <i><Text>Precondition: The device must be activated.</Text></i>
                <ol className={styles['list']}>
                    <li><Text>Navigate to the 'Devices' page.</Text></li>
                    <li><Text>Click the 'Action' button.</Text></li>
                    <li><Text>Click the 'Playlist' button (this will open a drawer).</Text></li>
                    <li><Text>Click 'Add Playlist'.</Text></li>
                    <li><Text>Choose to 'Hide URL' or  'Unhide' ( if you want the user to see the playlist URL in the app)</Text></li>
                    <li><Text>Navigate to the 'Devices' page.</Text></li>
                    <li><Text>Click the 'Save' button.</Text></li>
                </ol>
                <Alert
                    message={<><strong style={{ color: "red" }}><Text>NOTE</Text>:</strong> Y<Text>ou can also edit and delete the playlist.</Text></>}
                    type="warning"
                />
            </>}
        />
    )
}
