import useStore from '../hooks/use-store';

import TEXTS from '../config/resellerText';

export default function Text({ children }) {
  const [language] = useStore("language");

  if (TEXTS[children] && TEXTS[children][language]) {

    return TEXTS[children][language];
  } else {

    return children;
  }
}
