import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Card, Avatar, Form, Input, Button, Divider, Flex, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import useStore from '../../hooks/use-store';

import passwordGenerator from '../../utils/passwordGenerator';

import Text from '../../components/Text';
import TEXTS from '../../config/resellerText';

import styles from "./styles.module.scss";

export default function ProfilePage() {
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const location = useLocation();

    const navigate = useNavigate()

    const [profile, dispatch] = useStore("profile");

    const [language] = useStore("language");

    const getProfile = () => {
        REQUESTS.PROFILE.GET(
            (data) => {
                dispatch({ type: "profile/setProfile", payload: data })
            },
            (error) => {
                navigate("/")
            });
    }

    const randomPasswordGenerator = () => {
        let password = passwordGenerator();

        form.setFieldsValue({
            password: password,
            confirm_password: password
        },
        )
    }

    const onFinish = (values) => {
        const { name, password, confirm_password } = values;
        setLoading(true)
        const body = {
            name
        }

        if (password.length) {
            body["password"] = password;
            body["confirm_password"] = confirm_password;
        }

        REQUESTS.PROFILE.UPDATE(
            body,
            (data) => {
                setLoading(false)
                getProfile();

                notification.success({
                    message: TEXTS["Profile Updated"][language],
                    description: TEXTS["Your profile has been updated successfully"][language]
                })
            },
            (error) => {
                setLoading(false)
                notification.error({
                    message: "Error",
                    description: error
                })
            }
        )
    }

    useEffect(() => {
        const content = document.getElementById("ant-layout-content");

        if (location.pathname.includes("profile") && content) {
            content.style.background = "#f5f5f5";
        }

        return () => {
            if (content) {
                content.style.background = "#fff";
            }
        }
    }, [])

    useEffect(() => {
        if (profile) {
            form.setFields([
                {
                    name: "name",
                    value: profile.name
                },
                {
                    name: "email",
                    value: profile.email
                },
            ])
        }

    }, [profile])

    return (
        <div>
            <Divider
                orientation="left"
            >
                <Text>Account</Text>
            </Divider>
            <Card
                title={null}
                bordered={false}
                className={styles["card-style"]}
            >
                <div className={styles["card-content"]}>
                    <Avatar size={40} icon={<UserOutlined />} />
                    <div>
                        <h3>{profile.name}</h3>
                        <p>{profile.email}</p>
                    </div>
                </div>
            </Card>
            <Divider orientation="left"><Text>Update Profile</Text></Divider>
            <Form
                form={form}
                name="basic"
                style={{
                    maxWidth: 600,
                }}
                autoComplete="off"
                layout='vertical'
                initialValues={{
                    name: "",
                    email: "",
                    password: "",
                    confirm_password: ""
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="name"
                    label={<Text>Name</Text>}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label={<Text>Email</Text>}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={<Text>Password</Text>}
                    rules={[{
                        min: 8,
                        message: <Text>Password must be at least 8 characters!</Text>
                    },]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm_password"
                    label={<Text>Confirm Password</Text>}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(TEXTS["The new password that you entered do not match!"][language]));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Button
                    onClick={randomPasswordGenerator}
                    style={{ marginBottom: 20 }}
                >
                    <Text>Generate a Password</Text>
                </Button>
                <Form.Item >
                    <Flex justify='center'>
                        <Button
                            htmlType="submit"
                            type='primary'
                            loading={loading}
                        >
                            <Text>Save</Text>
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </div >
    )
}
