export default function passwordGenerator() {
    const length = 8;
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*_+";
    let password = "";
    let randomIndex = ""

    for (let i = 0; i <= length; i++) {
        randomIndex = Math.floor(Math.random() * chars.length);
        password += chars[randomIndex];
    }

    return password;
}