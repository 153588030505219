import { Modal } from 'antd';

import { InfoCircleOutlined } from "@ant-design/icons";

import Text from './Text';

export default function InfoModal({ open, handleCancel, title, text }) {
    return (
        <Modal
            title={<div style={{
                display: "flex",
                alignItems: "center",
                gap: 5
            }}>
                <InfoCircleOutlined style={{ color: "blue", fontSize: 14 }} />
                {title}
            </div>}
            open={open}
            onOk={handleCancel}
            onCancel={handleCancel}
            cancelText={<Text>Cancel</Text>}
            okText={<Text>OK</Text>}
        >
            {text}
        </Modal >
    )
}
