import React, { useEffect, useState } from 'react'

import { Button, Input, Table, Flex, Tooltip } from 'antd';
import { SearchOutlined, StockOutlined } from '@ant-design/icons';

import ActionDropdown from '../../utils/ActionDropdown';
import filterDropdown from '../../utils/FilterDropdown';
import dateFormat from '../../utils/dateFormat';
import useResize from '../../hooks/use-resize';

import { REQUESTS } from '../../api/requests';
import PageTemplate from '../../components/PageTemplate';
import Statistics from './components/Statistics';
import TotalStatistics from './components/TotalStatistics';
import Text from '../../components/Text';

export default function FreeDns() {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [sort, setSort] = useState(["createdAt", "DESC"]);
    const [search, setSearch] = useState("");
    // const [filter, setFilter] = useState({});
    const [date, setDate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(null);
    const [openTotalStat, setTotalStat] = useState(false);

    const resize = useResize()

    const getFreeDns = () => {
        setLoading(true);

        const params = {
            limit: limit,
            page: currentPage,
            sort,
        }

        if (search.length) {
            params.search = {
                dns: search,
                remark: search,
            }
        }

        const dateFilter = [
            { date: date, between: "createdAt" },
        ]

        dateFilter.forEach((item) => {
            if (item.date.length && item.date[0] && item.date[1]) {
                params['between'] = {
                    [item.between]: {
                        from: item.date[0] + " 00:00",
                        to: item.date[1] + " 23:59"
                    }
                }
            }
        })

        REQUESTS.FREE_DNS({ query: JSON.stringify(params) },
            (data) => {
                setData(data.rows);
                setTotal(data.count);
                setLoading(false);
            }, () => {
                setLoading(false);
            })
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                return (currentPage - 1) * limit + index + 1
            }
        },
        {
            title: "DNS",
            dataIndex: 'dns',
            sorter: true,
            render: (text) => <Tooltip title={text}>
                <div style={{ width: 200, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{text}</div>
            </Tooltip>
        },
        {
            title: <Text>Remark</Text>,
            key: 'remark',
            dataIndex: 'remark',
            sorter: true,
            ellipsis: true,
            width: 200,
            render: (text) => <Tooltip title={text}>
                <div style={{ width: 200, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{text}</div>
            </Tooltip>
        },
        {
            title: <Text>App Count</Text>,
            dataIndex: 'app_count',
            sorter: true,
        },
        {
            title: <Text>Created Date</Text>,
            dataIndex: 'updatedAt',
            sorter: true,
            ...filterDropdown(setDate),
            render: (text, record) => dateFormat(text)
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            align: "center",
            render: (text, record) => {
                return <ActionDropdown items={[
                    {
                        key: "Statistics",
                        label: <div >
                            <StockOutlined style={{ cursor: "pointer" }} /> <Text>Statistics</Text>
                        </div>,
                        onClick: (e) => handleAction(e, record)
                    },
                ]} />
            },
        },
    ];

    const handleAction = (e, item) => {
        switch (e.key) {
            case "Statistics":
                setOpen(true);
                setCurrent(item)
                break;
            default:
                break;
        }
    }
    const handleOnChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current)
        setLimit(pagination.pageSize)
        // setFilter(filters)
        if (sorter.field && sorter.order) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        } else {
            setSort(["updatedAt", "DESC"])
        }
    }

    useEffect(() => {
        let interval = setTimeout(() => {
            getFreeDns();
        }, 500);

        return () => {
            clearTimeout(interval)
        }
    }, [limit, currentPage, sort, search, date,])

    return <PageTemplate title={<Text>Free DNS</Text>}>
        <Flex justify='space-between' style={{ marginBottom: 20 }}>
            <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ width: 300 }}
            />
            <Button
                type="primary"
                onClick={() => setTotalStat(true)}
            >
                <Text>Total Statistics</Text>
            </Button>
        </Flex>
        <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            onChange={handleOnChange}
            pagination={{
                current: currentPage,
                total: total,
                pageSize: limit,
                showSizeChanger: true,
            }}
            bordered
            scroll={{ x: "max-content" }}
            size={resize <= 900 ? "small" : "large"}
        />
        <Statistics
            open={open}
            onCancel={() => {
                setOpen(false);
                setCurrent(null)
            }}
            current={current}
        />
        <TotalStatistics
            open={openTotalStat}
            onCancel={() => setTotalStat(false)}
        />
    </PageTemplate>
}
