import { useEffect, useState } from 'react';

import { Drawer, Form, Input, Button, Flex, Checkbox } from 'antd';

import { REQUESTS } from '../../../api/requests';

import Message from '../../../components/Message';

import passwordGenerator from '../../../utils/passwordGenerator';
import TEXTS from '../../../config/resellerText';
import Text from '../../../components/Text';
import useStore from '../../../hooks/use-store';

export default function SubResellerDrawer({ onClose, open, current, getSubResellers }) {
    const [form] = Form.useForm();

    const [language] = useStore("language");

    const [loading, setLoading] = useState(false);

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const randomPasswordGenerator = () => {
        let password = passwordGenerator();

        form.setFieldsValue({
            password: password,
            confirm_password: password
        },
        )
    }

    const onFinish = (values) => {
        const { name, email, password, confirm_password, blocked, remark } = values;

        setMessage({
            type: false,
            text: ""

        });

        setLoading(true);

        if (current) {
            const body = {
                id: current.id,
                name: name.trim(),
                blocked,
                remark
            }

            if (password && confirm_password) {
                body['password'] = password;
                body['confirm_password'] = confirm_password
            }

            REQUESTS.SUB_RESELLERS.UPDATE(
                body,
                (data) => {
                    setLoading(false)
                    onClose()
                    getSubResellers();
                },
                (err) => {
                    setLoading(false)
                    setMessage({
                        type: false,
                        text: err
                    })
                })
        } else {
            REQUESTS.SUB_RESELLERS.CREATE(
                {
                    name: name.trim(),
                    email,
                    password,
                    confirm_password,
                    remark
                },
                (data) => {
                    setLoading(false)
                    getSubResellers();
                    onClose()
                },
                (err) => {
                    setMessage({
                        type: false,
                        text: err
                    })
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setLoading(false);
            setMessage({
                type: false,
                text: ""

            })
        }
    }, [open])

    useEffect(() => {
        if (current && open) {
            form.setFields([
                {
                    name: "name",
                    value: current.name
                },
                {
                    name: "email",
                    value: current.email
                },
                {
                    name: "remark",
                    value: current.remark
                },
                {
                    name: "blocked",
                    value: current.blocked
                },
            ])
        }
    }, [current, open])

    return (
        <Drawer
            title={current ? <Text>Edit Sub Reseller</Text> : <Text>Create Sub Reseller</Text>}
            onClose={onClose}
            open={open}>
            <Form
                form={form}
                name="basic"
                style={{
                    maxWidth: 600,
                }}
                autoComplete="off"
                layout='vertical'
                onFinish={onFinish}
                initialValues={{
                    name: "",
                    email: "",
                    password: "",
                    remark: ""
                }}
                destroyOnClose={true}
            >
                <Form.Item
                    name="name"
                    label={<Text>Name</Text>}
                    rules={[
                        {
                            required: true,
                            message: <Text>Name is required!</Text>,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label={<Text>Email</Text>}
                    rules={[
                        {
                            required: true,
                            message: <Text>Email is required!</Text>,
                        },
                        {
                            type: "email",
                            message: <Text>Please enter valid email!</Text>
                        },
                    ]}
                >
                    <Input disabled={current} />
                </Form.Item>
                <Form.Item
                    name="remark"
                    label={<Text>Remark</Text>}
                    rules={[
                        {
                            max: 255,
                            message: <Text>Remark must be at least 255 characters</Text>
                        }
                    ]}
                >
                    <Input.TextArea style={{ resize: "none" }} />
                </Form.Item>
                <Form.Item
                    label={<Text>Password</Text>}
                    name="password"
                    rules={[
                        {
                            required: !current,
                            message: <Text>Password is required!</Text>
                        },
                        {
                            min: 8,
                            message: <Text>Password must be at least 8 characters!</Text>
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label={<Text>Confirm Password</Text>}
                    name="confirm_password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: !current,
                            message: <Text>Please confirm your password!</Text>,
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(TEXTS["The new password that you entered do not match!"][language]));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Button
                    onClick={randomPasswordGenerator}
                    style={{ marginBottom: 10 }}
                >
                    <Text>Generate a Password</Text>
                </Button>
                {
                    current &&
                    <Form.Item
                        name="blocked"
                        valuePropName="checked">
                        <Checkbox><Text>Block</Text></Checkbox>
                    </Form.Item>
                }
                <Flex justify='center'>
                    <Message message={message} />
                </Flex>
                <Form.Item >
                    <Flex justify="center">
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                        >
                            <Text>Save</Text>
                        </Button>
                    </Flex>
                </Form.Item>
            </Form >
        </Drawer >
    )
}
