import { Modal, Button, Flex } from 'antd';
import { InfoCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import useStore from '../../../hooks/use-store';
import dateFormat from '../../../utils/dateFormat';
import checkSchedule from '../../../utils/checkSchedule';
import Text from '../../../components/Text';

export default function CheckMacModal({ openModal, onClose, selectedApp }) {
    const [apps] = useStore("apps");

    return (
        <Modal
            open={openModal}
            onCancel={onClose}
            footer={null}
            closable={true}
        >
            {
                typeof openModal == 'string' ? <Flex vertical align='center'>
                    <ExclamationCircleOutlined style={{ color: "orange", fontSize: 18, marginBottom: 20 }} />
                    <p>{openModal}</p>
                    <Button
                        style={{ marginTop: 20 }}
                        onClick={onClose}
                    >
                        <Text>Close</Text>
                    </Button>
                </Flex>
                    :
                    <>
                        <Flex align='center' gap={15} style={{ marginBottom: 20 }}>
                            <InfoCircleOutlined
                                style={{ color: "blue", fontSize: 18, }}
                            />
                            <strong><Text>Mac Info</Text></strong>
                        </Flex>
                        <Flex justify='start' gap={10}>
                            <strong><Text>Application Name</Text>:</strong>{apps.find(app => app.id == selectedApp)?.name}
                        </Flex>
                        <Flex justify='start' gap={10}>
                            <strong><Text>Mac</Text>:</strong>{openModal.mac}
                        </Flex>
                        <Flex justify='start' gap={10}>
                            {
                                openModal.payed ?
                                    <>
                                        <strong><Text>Expire Date</Text>:</strong> {
                                            checkSchedule(openModal.activation_expired) == "yearly" ?
                                                dateFormat(openModal.activation_expired) :
                                                <Text>Lifetime</Text>
                                        }</> :
                                    <>
                                        <strong><Text>Free Trial Expired</Text>:</strong>
                                        {dateFormat(openModal.free_trial_expired)}
                                    </>
                            }
                        </Flex>
                        <Flex justify='start' gap={10}>
                            <strong>Status:</strong>{openModal.payed ? 'Paid' :
                                openModal.free_trial == 2 ?
                                    <Text>Free Trial Expired</Text> :
                                    <Text>Free Trial</Text>}
                        </Flex>
                        <Button
                            style={{ marginTop: 20 }}
                            onClick={onClose}
                        >
                            <Text>Close</Text>
                        </Button>
                    </>
            }
        </Modal>
    )
}
