const dateFormat = (date) => {
    const newDate = new Date(date) || new Date();
    const year = newDate.getFullYear();
    let month = newDate.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    let day = newDate.getDate();
    day = day < 10 ? `0${day}` : day;
    let hours = newDate.getHours();
    hours = hours < 10 ? `0${hours}` : hours;
    let minutes = newDate.getMinutes();
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${year}-${month}-${day} ${hours}:${minutes}`
}

export default dateFormat;