const TEXTS = {
    "Today's Activated Devices": {
        "en": "Today's Activated Devices",
        "br": "Dispositivos ativados hoje",
        "ar": "الأجهزة المفعلة اليوم",
    },
    "Profile Updated": {
        "en": "Profile Updated",
        "br": "Perfil atualizado",
        "ar": "تم تحديث الملف الشخصي",
    },
    "Your profile has been updated successfully": {
        "en": "Your profile has been updated successfully",
        "br": "Seu perfil foi atualizado com sucesso",
        "ar": "تم تحديث ملفك الشخصي بنجاح",
    },
    "Total Activated Devices": {
        "en": "Total Activated Devices",
        "br": "Total de dispositivos ativados",
        "ar": "إجمالي الأجهزة المفعلة",
    },

    "Credit Point": {
        "en": "Credit Point",
        "br": "Ponto de crédito",
        "ar": "نقطة الائتمان",
    },
    "Success": {
        "en": "Success",
        "br": "Sucesso",
        "ar": "نجاح",
    },
    "Resellers": {
        "en": "Resellers",
        "br": "Revendedores",
        "ar": "الموزعين",
    },

    "Total Credit Share": {
        "en": "Total Credit Share",
        "br": "Compartilhamento total de crédito",
        "ar": "إجمالي حصة الائتمان",
    },

    "Dashboard": {
        "en": "Dashboard",
        "br": "Painel de controle",
        "ar": "لوحة القيادة",
    },

    "Resellers": {
        "en": "Resellers",
        "br": "Revendedores",
        "ar": "الموزعين",
    },

    "Devices": {
        "en": "Devices",
        "br": "Dispositivos",
        "ar": "الأجهزة",
    },

    "Activation": {
        "en": "Activation",
        "br": "Ativação",
        "ar": "تنشيط",
    },

    "Check Mac": {
        "en": "Check Mac",
        "br": "Verificar Mac",
        "ar": "تحقق من Mac",
    },

    "Credits History": {
        "en": "Credits History",
        "br": "Histórico de créditos",
        "ar": "تاريخ الرصيد",
    },

    "Apps & Links": {
        "en": "Apps & Links",
        "br": "Aplicativos e Links",
        "ar": "التطبيقات والروابط",
    },

    "Logout": {
        "en": "Logout",
        "br": "Sair",
        "ar": "تسجيل الخروج",
    },

    "Reseller": {
        "en": "Reseller",
        "br": "Revendedor",
        "ar": "الموزع",
    },

    "Devices Count By App": {
        "en": "Devices Count By App",
        "br": "Contagem de dispositivos por aplicativo",
        "ar": "عدد الأجهزة حسب التطبيق",
    },

    "Devices Count By Platform": {
        "en": "Devices Count By Platform",
        "br": "Contagem de dispositivos por plataforma",
        "ar": "عدد الأجهزة حسب المنصة",
    },

    "Devices By Country": {
        "en": "Devices By Country",
        "br": "Dispositivos por país",
        "ar": "الأجهزة حسب البلد",
    },

    "Cancel": {
        "en": "Cancel",
        "br": "Cancelar",
        "ar": "إلغاء",
    },
    "Chart statistics": {
        "en": "Chart statistics",
        "br": "Estatísticas do gráfico",
        "ar": "إحصائيات الرسم البياني",
    },
    "Today`s activated devices": {
        "en": "Today`s activated devices",
        "br": "Dispositivos ativados hoje",
        "ar": "الأجهزة المفعلة اليوم",
    },
    "The card shows the total count of of activated devices.": {
        "en": "The card shows the total count of of activated devices.",
        "br": "O cartão mostra o total de dispositivos ativados.",
        "ar": "البطاقة تظهر العدد الإجمالي للأجهزة المفعلة.",
    },
    "Total Activated Devices": {
        "en": "Total Activated Devices",
        "br": "Total de dispositivos ativados",
        "ar": "إجمالي الأجهزة المفعلة",
    },
    "The card shows the total number of activated devices from registered day.": {
        "en": "The card shows the total number of activated devices from registered day.",
        "br": "O cartão mostra o número total de dispositivos ativados desde o dia do registro.",
        "ar": "البطاقة تظهر العدد الإجمالي للأجهزة المفعلة منذ يوم التسجيل.",
    },

    "Total count of subresellers registered that belongs to  you.": {
        "en": "Total count of subresellers registered that belongs to  you.",
        "br": "Contagem total de subrevendedores registrados que pertencem a você.",
        "ar": "إجمالي عدد الموزعين الفرعيين المسجلين الذين ينتمون إليك.",
    },
    "Total Credit Share": {
        "en": "Total Credit Share",
        "br": "Compartilhamento total de crédito",
        "ar": "إجمالي حصة الائتمان",
    },
    "The final count of credits that parent reseller shares with his subresellers.": {
        "en": "The final count of credits that parent reseller shares with his subresellers.",
        "br": "A contagem final de créditos que o revendedor pai compartilha com seus subrevendedores.",
        "ar": "العدد النهائي للرصيد الذي يشاركه الموزع الأصلي مع موزعيه الفرعيين.",
    },
    "Devices count by App": {
        "en": "Devices count by App",
        "br": "Contagem de dispositivos por aplicativo",
        "ar": "عدد الأجهزة حسب التطبيق",
    },

    "The Pie chart shows the total number of registered devices in each app.": {
        "en": "The Pie chart shows the total number of registered devices in each app.",
        "br": "O gráfico de pizza mostra o número total de dispositivos registrados em cada aplicativo.",
        "ar": "يظهر الرسم البياني الدائري العدد الإجمالي للأجهزة المسجلة في كل تطبيق.",
    },

    "The bar chart shows the App names by decresing count of registered devices on them.": {
        "en": "The bar chart shows the App names by decresing count of registered devices on them.",
        "br": "O gráfico de barras mostra os nomes dos aplicativos pela contagem decrescente de dispositivos registrados neles.",
        "ar": "يظهر الرسم البياني الشريطي أسماء التطبيقات حسب العدد المنخفض للأجهزة المسجلة عليها.",
    },

    "Devices by country": {
        "en": "Devices by country",
        "br": "Dispositivos por país",
        "ar": "الأجهزة حسب البلد",
    },

    "The map shows the devices count by country": {
        "en": "The map shows the devices count by country",
        "br": "O mapa mostra a contagem de dispositivos por país",
        "ar": "يظهر الخريطة عدد الأجهزة حسب البلد",
    },

    "Name": {
        "en": "Name",
        "br": "Nome",
        "ar": "اسم",
    },

    "Email": {
        "en": "Email",
        "br": "O email",
        "ar": "البريد الإلكتروني",
    },
    "Activated Devices": {
        "en": "Activated Devices",
        "br": "Dispositivos ativados",
        "ar": "الأجهزة المفعلة",
    },
    "Remark": {
        "en": "Remark",
        "br": "Observação",
        "ar": "ملاحظة",
    },
    "Created Date": {
        "en": "Created Date",
        "br": "Data de criação",
        "ar": "تاريخ الإنشاء",
    },
    "Status": {
        "en": "Status",
        "br": "Status",
        "ar": "الحالة",
    },
    "Blocked": {
        "en": "Blocked",
        "br": "Bloqueado",
        "ar": "محظور",
    },
    "Active": {
        "en": "Active",
        "br": "Ativo",
        "ar": "نشيط",
    },
    "Edit": {
        "en": "Edit",
        "br": "Editar",
        "ar": "تصحيح",
    },
    "Add Credit": {
        "en": "Add Credit",
        "br": "Adicionar crédito",
        "ar": "إضافة رصيد",
    },
    "Take Credit": {
        "en": "Take Credit",
        "br": "Pegar crédito",
        "ar": "خذ الائتمان",
    },
    "Add Reseller": {
        "en": "Add Reseller",
        "br": "Adicionar revendedor",
        "ar": "إضافة موزع",
    },
    "Total": {
        "en": "Total",
        "br": "Total",
        "ar": "مجموع",
    },
    "Reseller can see the subresellers`s list by email and name.": {
        "en": " Reseller can see the subresellers`s list by email and name.",
        "br": " O revendedor pode ver a lista de subrevendedores por e-mail e nome.",
        "ar": " يمكن للموزع رؤية قائمة الموزعين الفرعيين حسب البريد الإلكتروني والاسم.",
    },
    "Search is done by name and email address.": {
        "en": "Search is done by name and email address.",
        "br": "A pesquisa é feita por nome e endereço de e-mail.",
        "ar": "يتم البحث حسب الاسم وعنوان البريد الإلكتروني.",
    },
    "Expire date shows the date till when the subreseller account is active.": {
        "en": "Expire date shows the date till when the subreseller account is active.",
        "br": "A data de validade mostra a data até quando a conta do subrevendedor está ativa.",
        "ar": "تظهر تاريخ الانتهاء التاريخ حتى يكون حساب الموزع الفرعي نشطًا.",
    },
    "Credit Points are the total active credits that subreseller has.": {
        "en": "Credit Points are the total active credits that subreseller has.",
        "br": "Os pontos de crédito são os créditos ativos totais que o subrevendedor possui.",
        "ar": "نقاط الائتمان هي الرصيد النشط الإجمالي الذي يمتلكه الموزع الفرعي.",
    },
    "The activated devices shows the total number of activated devices during the whole time by that subreseller.": {
        "en": "The activated devices shows the total number of activated devices during the whole time by that subreseller.",
        "br": "Os dispositivos ativados mostram o número total de dispositivos ativados durante todo o tempo por esse subrevendedor.",
        "ar": "تظهر الأجهزة المفعلة العدد الإجمالي للأجهزة المفعلة خلال كل الوقت من قبل ذلك الموزع الفرعي.",
    },
    "Created date is the date when the subreseller has been created.": {
        "en": "Created date is the date when the subreseller has been created.",
        "br": "A data de criação é a data em que o subrevendedor foi criado.",
        "ar": "تاريخ الإنشاء هو تاريخ إنشاء الموزع الفرعي.",
    },
    "Status field shows the subreseller status as active or expired.": {
        "en": "Status field shows the subreseller status as active or expired.",
        "br": "O campo de status mostra o status do subrevendedor como ativo ou expirado.",
        "ar": "يعرض حقل الحالة حالة الموزع الفرعي كنشط أو منتهي الصلاحية.",
    },
    "From Actions reseller can Add and Take credits to/from subresellers.": {
        "en": "From Actions reseller can Add and Take credits to/from subresellers.",
        "br": "Das Ações, o revendedor pode adicionar e retirar créditos de / para subrevendedores.",
        "ar": "من الإجراءات يمكن للموزع إضافة وسحب الرصيد من / إلى الموزعين الفرعيين.",
    },
    "Reseller can Edit subreseller info from Actions by changing the name and Expire date only.": {
        "en": "Reseller can Edit subreseller info from Actions by changing the name and Expire date only.",
        "br": "O revendedor pode editar as informações do subrevendedor nas Ações alterando apenas o nome e a data de validade.",
        "ar": "يمكن للموزع تحرير معلومات الموزع الفرعي من الإجراءات عن طريق تغيير الاسم وتاريخ الانتهاء فقط.",
    },
    "By Add reseller reseller can add subreseller by generating a password with special password generator and all the fields here are required.": {
        "en": "By Add reseller reseller can add subreseller by generating a password with special password generator and all the fields here are required.",
        "br": "Ao adicionar o revendedor, o revendedor pode adicionar um subrevendedor gerando uma senha com um gerador de senha especial e todos os campos aqui são obrigatórios.",
        "ar": "بإضافة الموزع يمكن للموزع إضافة موزع فرعي عن طريق إنشاء كلمة مرور باستخدام مولد كلمة مرور خاص وجميع الحقول مطلوبة هنا.",
    },
    "NOTE": {
        "en": "NOTE",
        "br": "NOTA",
        "ar": "ملحوظة",
    },
    "Reseller can change his password from his panel or super admin can change it.": {
        "en": "Reseller can change his password from his panel or super admin can change it.",
        "br": "O revendedor pode alterar sua senha em seu painel ou o super administrador pode alterá-la.",
        "ar": "يمكن للموزع تغيير كلمة المرور الخاصة به من لوحته أو يمكن للمشرف الفائق تغييرها.",
    },
    "How to Add a Playlist?": {
        "en": "How to Add a Playlist?",
        "br": "Como adicionar uma playlist?",
        "ar": "كيفية إضافة قائمة تشغيل؟",
    },
    "Precondition: The device must be activated.": {
        "en": "Precondition: The device must be activated.",
        "br": "Pré-condição: o dispositivo deve estar ativado.",
        "ar": "الشرط المسبق: يجب تنشيط الجهاز.",
    },
    "Navigate to the 'Devices' page.": {
        "en": "Navigate to the 'Devices' page.",
        "br": "Navegue até a página 'Dispositivos'.",
        "ar": "انتقل إلى صفحة 'الأجهزة'.",
    },
    "Click the 'Action' button.": {
        "en": "Click the 'Action' button.",
        "br": "Clique no botão 'Ação'.",
        "ar": "انقر فوق الزر 'إجراء'.",
    },
    "Click the 'Playlist' button (this will open a drawer).": {
        "en": "Click the 'Playlist' button (this will open a drawer).",
        "br": "Clique no botão 'Playlist' (isso abrirá uma gaveta).",
        "ar": "انقر فوق الزر 'قائمة التشغيل' (سيفتح هذا درجًا).",
    },
    "Click 'Add Playlist'.": {
        "en": "Click 'Add Playlist'.",
        "br": "Clique em 'Adicionar playlist'.",
        "ar": "انقر فوق 'إضافة قائمة تشغيل'.",
    },
    "Choose to 'Hide URL' or  'Unhide' ( if you want the user to see the playlist URL in the app)": {
        "en": "Choose to 'Hide URL' or  'Unhide' ( if you want the user to see the playlist URL in the app)",
        "br": "Escolha 'Ocultar URL' ou 'Mostrar' (se você deseja que o usuário veja a URL da playlist no aplicativo)",
        "ar": "اختر 'إخفاء URL' أو 'إظهار' (إذا كنت ترغب في رؤية مستخدم URL قائمة التشغيل في التطبيق)",
    },
    "Click the 'Save' button.": {
        "en": "Click the 'Save' button.",
        "br": "Clique no botão 'Salvar'.",
        "ar": "انقر فوق الزر 'حفظ'.",
    },
    "ou can also edit and delete the playlist.": {
        "en": "ou can also edit and delete the playlist.",
        "br": "ou também pode editar e excluir a playlist.",
        "ar": "يمكنك أيضًا تحرير وحذف قائمة التشغيل.",
    },
    "Edit Sub Reseller": {
        "en": "Edit Sub Reseller",
        "br": "Editar subrevendedor",
        "ar": "تحرير الموزع الفرعي",
    },
    "Create Sub Reseller": {
        "en": "Create Sub Reseller",
        "br": "Criar subrevendedor",
        "ar": "إنشاء موزع فرعي",
    },
    "Name is required!": {
        "en": "Name is required!",
        "br": "Nome é obrigatório!",
        "ar": "الاسم مطلوب!",
    },
    "Email is required!": {
        "en": "Email is required!",
        "br": "O email é obrigatório!",
        "ar": "البريد الإلكتروني مطلوب!",
    },
    "Please enter valid email!": {
        "en": "Please enter valid email!",
        "br": "Por favor, insira um e-mail válido!",
        "ar": "الرجاء إدخال بريد إلكتروني صالح!",
    },
    "Remark must be at least 255 characters": {
        "en": "Remark must be at least 255 characters",
        "br": "A observação deve ter pelo menos 255 caracteres",
        "ar": "يجب أن تكون الملاحظة على الأقل 255 حرفًا",
    },
    "Password is required!": {
        "en": "Password is required!",
        "br": "Senha é obrigatória!",
        "ar": "كلمة المرور مطلوبة!",
    },
    "Password must be at least 8 characters!": {
        "en": "Password must be at least 8 characters!",
        "br": "A senha deve ter pelo menos 8 caracteres!",
        "ar": "يجب أن تكون كلمة المرور على الأقل 8 أحرف!",
    },
    "Confirm Password": {
        "en": "Confirm Password",
        "br": "Confirme a Senha",
        "ar": "تأكيد كلمة المرور",
    },
    "Please confirm your password!": {
        "en": "Please confirm your password!",
        "br": "Por favor, confirme sua senha!",
        "ar": "يرجى تأكيد كلمة المرور الخاصة بك!",
    },
    "The new password that you entered do not match!": {
        "en": "The new password that you entered do not match!",
        "br": "A nova senha que você digitou não corresponde!",
        "ar": "كلمة المرور الجديدة التي أدخلتها لا تتطابق!",
    },
    "Generate a Password": {
        "en": "Generate a Password",
        "br": "Gerar uma senha",
        "ar": "إنشاء كلمة مرور",
    },
    "Block": {
        "en": "Block",
        "br": "Bloquear",
        "ar": "منع",
    },
    "Save": {
        "en": "Save",
        "br": "Salve",
        "ar": "حفظ",
    },
    "Password": {
        "en": "Password",
        "br": "Senha",
        "ar": "كلمه السر",
    },
    "You have {credit} credit points balance. Please allot credit point lesser than your current credit!": {
        "en": "You have {credit} credit points balance. Please allot credit point lesser than your current credit!",
        "br": "Você tem {credit} pontos de crédito de saldo. Por favor, aloque ponto de crédito menor que seu crédito atual!",
        "ar": "لديك {credit} رصيد نقاط الائتمان. يرجى تخصيص نقطة الائتمان أقل من رصيدك الحالي!",
    },
    "Reseller have ${current?.credits} credit points balance. Please deduct credit point lesser or equal to current credit!": {
        "en": "Reseller have ${current?.credits} credit points balance. Please deduct credit point lesser or equal to current credit!",
        "br": "Revendedor tem ${current?.credits} pontos de crédito de saldo. Por favor, deduza o ponto de crédito menor ou igual ao crédito atual!",
        "ar": "لدى الموزع ${current?.credits} رصيد نقاط الائتمان. يرجى خصم نقطة الائتمان أقل أو تساوي الرصيد الحالي!",
    },
    "Credits": {
        "en": "Credits",
        "br": "Créditos",
        "ar": "اعتمادات",
    },
    "Credits is required!": {
        "en": "Credits is required!",
        "br": "Créditos são obrigatórios!",
        "ar": "الاعتمادات مطلوبة!",
    },
    "Add Credit": {
        "en": "Add Credit",
        "br": "Adicionar crédito",
        "ar": "إضافة رصيد",
    },
    "Action": {
        "en": "Action",
        "br": "Ação",
        "ar": "عمل",
    },
    "Credit value must be greater than or equal to 0!": {
        "en": "Credit value must be greater than or equal to 0!",
        "br": "O valor do crédito deve ser maior ou igual a 0!",
        "ar": "يجب أن يكون قيمة الائتمان أكبر من أو تساوي 0!",
    },
    "Credit value must be less than or equal to your available credits!": {
        "en": "Credit value must be less than or equal to your available credits!",
        "br": "O valor do crédito deve ser menor ou igual aos seus créditos disponíveis!",
        "ar": "يجب أن تكون قيمة الائتمان أقل من أو تساوي الرصيد المتاح لديك!",
    },
    "Credit value must be less than or equal to sub reseller credits!": {
        "en": "Credit value must be less than or equal to sub reseller credits!",
        "br": "O valor do crédito deve ser menor ou igual aos créditos do subrevendedor!",
        "ar": "يجب أن تكون قيمة الائتمان أقل من أو تساوي رصيد الموزع الفرعي!",
    },
    "You have": {
        "en": "You have",
        "br": "Você tem",
        "ar": "لديك",
    },
    "credit points balance.": {
        "en": "credit points balance.",
        "br": "pontos de crédito de saldo.",
        "ar": "رصيد نقاط الائتمان.",
    },
    "Please allot credit point lesser than your current credit!": {
        "en": "Please allot credit point lesser than your current credit!",
        "br": "Por favor, aloque ponto de crédito menor que seu crédito atual!",
        "ar": "يرجى تخصيص نقطة الائتمان أقل من رصيدك الحالي!",
    },
    "Reseller have": {
        "en": "Reseller have",
        "br": "Revendedor tem",
        "ar": "لدى الموزع",
    },
    "Please deduct credit point lesser or equal to current credit!": {
        "en": "Please deduct credit point lesser or equal to current credit!",
        "br": "Por favor, deduza o ponto de crédito menor ou igual ao crédito atual!",
        "ar": "يرجى خصم نقطة الائتمان أقل أو تساوي الرصيد الحالي!",
    },
    "Activated By": {
        "en": "Activated By",
        "br": "Ativado por",
        "ar": "تم التنشيط بواسطة",
    },
    "App Name": {
        "en": "App Name",
        "br": "Nome do aplicativo",
        "ar": "اسم التطبيق",
    },
    "Used Credits": {
        "en": "Used Credits",
        "br": "Créditos usados",
        "ar": "الاعتمادات المستخدمة",
    },
    "Activated Date": {
        "en": "Activated Date",
        "br": "Data de ativação",
        "ar": "تاريخ التنشيط",
    },
    "Expired Date": {
        "en": "Expired Date",
        "br": "Data de validade",
        "ar": "تاريخ الانتهاء",
    },
    "Playlists": {
        "en": "Playlists",
        "br": "Listas de reprodução",
        "ar": "قوائم التشغيل",
    },
    "Devices": {
        "en": "Devices",
        "br": "Dispositivos",
        "ar": "الأجهزة",
    },
    "Search": {
        "en": "Search",
        "br": "Procurar",
        "ar": "بحث"
    },
    "Devices list MAC address and search also is done by MAC address.": {
        "en": "Devices list MAC address and search also is done by MAC address.",
        "br": "A lista de dispositivos endereço MAC e a pesquisa também são feitas por endereço MAC.",
        "ar": "قائمة عناوين MAC للأجهزة والبحث يتم أيضًا عن طريق عنوان MAC."
    },
    "Search is done by MAC address and activated by email.": {
        "en": "Search is done by MAC address and activated by email.",
        "br": "A pesquisa é feita por endereço MAC e ativada por e-mail.",
        "ar": "يتم البحث حسب عنوان MAC وتنشيطه عبر البريد الإلكتروني."
    },
    "Activated by shows the email who activated the device.": {
        "en": "Activated by shows the email who activated the device.",
        "br": "Ativado por mostra o e-mail que ativou o dispositivo.",
        "ar": "تظهر البريد الإلكتروني الذي قام بتنشيط الجهاز."
    },
    "App name gives the name on what app the device has been activated": {
        "en": "App name gives the name on what app the device has been activated",
        "br": "O nome do aplicativo dá o nome do aplicativo no qual o dispositivo foi ativado",
        "ar": "اسم التطبيق يعطي الاسم على أي تطبيق تم تنشيط الجهاز"
    },
    "Used credits are the count of credits that are used for the device activation.": {
        "en": "Used credits are the count of credits that are used for the device activation.",
        "br": "Os créditos usados são a contagem de créditos que são usados para a ativação do dispositivo.",
        "ar": "الاعتمادات المستخدمة هي عدد الاعتمادات التي تم استخدامها لتنشيط الجهاز."
    },
    "Activated and Expire Dates shows the created and expired date of the device.": {
        "en": "Activated and Expire Dates shows the created and expired date of the device.",
        "br": "As datas de ativação e expiração mostram a data de criação e expiração do dispositivo.",
        "ar": "تظهر تواريخ التنشيط والانتهاء تاريخ إنشاء الجهاز وتاريخ انتهاء صلاحيته."
    },
    "Hide URL": {
        "en": "Hide URL",
        "br": "Ocultar URL",
        "ar": "إخفاء URL"
    },
    "Delete": {
        "en": "Delete",
        "br": "Excluir",
        "ar": "حذف"
    },
    "Yes": {
        "en": "Yes",
        "br": "Sim",
        "ar": "نعم"
    },
    "No": {
        "en": "No",
        "br": "Não",
        "ar": "لا"
    },
    "Do you want to delete this playlist?": {
        "en": "Do you want to delete this playlist?",
        "br": "Você quer excluir esta playlist?",
        "ar": "هل تريد حذف هذه قائمة التشغيل؟"
    },
    "Add Playlist": {
        "en": "Add Playlist",
        "br": "Adicionar playlist",
        "ar": "إضافة قائمة تشغيل"
    },
    "Edit Playlist": {
        "en": "Edit Playlist",
        "br": "Editar playlist",
        "ar": "تحرير قائمة التشغيل"
    },
    "URL is required!": {
        "en": "URL is required!",
        "br": "URL é obrigatório!",
        "ar": "الرابط مطلوب!"
    },
    "Please enter a valid URL!": {
        "en": "Please enter a valid URL!",
        "br": "Por favor, insira um URL válido!",
        "ar": "الرجاء إدخال عنوان URL صالح!"
    },
    "Reseller can activate device by choosing from available apps entering MAC address and choosing the package and add a nite if needed.": {
        "en": "Reseller can activate device by choosing from available apps entering MAC address and choosing the package and add a nite if needed.",
        "br": "O revendedor pode ativar o dispositivo escolhendo entre os aplicativos disponíveis, inserindo o endereço MAC e escolhendo o pacote e adicionando uma noite, se necessário.",
        "ar": "يمكن للموزع تنشيط الجهاز عن طريق اختيار التطبيقات المتاحة وإدخال عنوان MAC واختيار الحزمة وإضافة ليلة إذا لزم الأمر."
    },
    "If MAC address is active yearly and the reseller activates it it will lenghten the expire date of the device.": {
        "en": "If MAC address is active yearly and the reseller activates it it will lenghten the expire date of the device.",
        "br": "Se o endereço MAC estiver ativo anualmente e o revendedor o ativar, ele prolongará a data de validade do dispositivo.",
        "ar": "إذا كان عنوان MAC نشطًا سنويًا وقام الموزع بتنشيطه ، فسيطيل تاريخ انتهاء صلاحية الجهاز."
    },
    "If the MAC address is not registered in the system it will not be activated.The user will need to donwload the app before the activation of the reseller.": {
        "en": "If the MAC address is not registered in the system it will not be activated.The user will need to donwload the app before the activation of the reseller.",
        "br": "Se o endereço MAC não estiver registrado no sistema, ele não será ativado. O usuário precisará baixar o aplicativo antes da ativação do revendedor.",
        "ar": "إذا لم يتم تسجيل عنوان MAC في النظام ، فلن يتم تنشيطه. سيحتاج المستخدم إلى تنزيل التطبيق قبل تنشيط الموزع."
    },
    "Activation": {
        "en": "Activation",
        "br": "Ativação",
        "ar": "تنشيط"
    },
    "Select App": {
        "en": "Select App",
        "br": "Selecione o aplicativo",
        "ar": "حدد التطبيق"
    },
    "Please select an app!": {
        "en": "Please select an app!",
        "br": "Por favor, selecione um aplicativo!",
        "ar": "يرجى تحديد تطبيق!"
    },
    "Select Package": {
        "en": "Select Package",
        "br": "Selecione o pacote",
        "ar": "حدد الحزمة"
    },
    "Please select package!": {
        "en": "Please select package!",
        "br": "Por favor, selecione o pacote!",
        "ar": "يرجى تحديد الحزمة!"
    },
    "Mac Address": {
        "en": "Mac Address",
        "br": "Endereço MAC",
        "ar": "عنوان MAC"
    },
    "Please enter Mac address!": {
        "en": "Please enter Mac address!",
        "br": "Por favor, insira o endereço MAC!",
        "ar": "الرجاء إدخال عنوان Mac!"
    },
    "Activate": {
        "en": "Activate",
        "br": "Ativar",
        "ar": "تفعيل"
    },
    "Lifetime": {
        "en": "Lifetime",
        "br": "Para a vida",
        "ar": "مدى الحياة"
    },
    "Year": {
        "en": "Year",
        "br": "Ano",
        "ar": "عام"
    },
    "This": {
        "en": "This",
        "br": "Este",
        "ar": "هذا"
    },
    "has been activated with": {
        "en": "has been activated with",
        "br": "foi ativado com",
        "ar": "تم تنشيطه مع"
    },
    "on the": {
        "en": "on the",
        "br": "no",
        "ar": "على"
    },
    "Do you still want to activate and extend expiry period?": {
        "en": "Do you still want to activate and extend expiry period?",
        "br": "Você ainda deseja ativar e estender o período de validade?",
        "ar": "هل تريد ما زلت تنشيط وتمديد فترة الانتهاء؟"
    },
    "Mac already activated until": {
        "en": "Mac already activated until",
        "br": "Mac já ativado até",
        "ar": "تم تنشيط Mac بالفعل حتى"
    },
    "This device is already lifetime activated": {
        "en": "This device is already lifetime activated",
        "br": "Este dispositivo já está ativado vitaliciamente",
        "ar": "تم تنشيط هذا الجهاز مدى الحياة بالفعل"
    },
    "Error": {
        "en": "Error",
        "br": "Erro",
        "ar": "خطأ"
    },
    "Activate Account": {
        "en": "Activate Account",
        "br": "Ativar conta",
        "ar": "تنشيط الحساب"
    },
    "Please select an app!": {
        "en": "Please select an app!",
        "br": "Por favor, selecione um aplicativo!",
        "ar": "يرجى تحديد تطبيق!"
    },
    "Check": {
        "en": "Check",
        "br": "Verificar",
        "ar": "تحقق"
    },
    "Close": {
        "en": "Close",
        "br": "Fechar",
        "ar": "قريب"
    },
    "Mac Info": {
        "en": "Mac Info",
        "br": "Informações do Mac",
        "ar": "معلومات Mac"
    },
    "Application Name": {
        "en": "Application Name",
        "br": "Nome do aplicativo",
        "ar": "اسم التطبيق"
    },
    "Expire Date": {
        "en": "Expire Date",
        "br": "Data de validade",
        "ar": "تاريخ الانتهاء"
    },
    "Free Trial Expired": {
        "en": "Free Trial Expired",
        "br": "Teste gratuito expirado",
        "ar": "انتهت المدة التجريبية المجانية"
    },
    "Free Trial": {
        "en": "Free Trial",
        "br": "Teste gratuito",
        "ar": "تجربة مجانية"
    },
    "Check Mac": {
        "en": "Check Mac",
        "br": "Verificar Mac",
        "ar": "تحقق من Mac"
    },
    "Check  MAC functional is for checking the MAC address is registered in the system or is activated or not.Reseller can check the MAC address app by app.": {
        "en": "Check  MAC functional is for checking the MAC address is registered in the system or is activated or not.Reseller can check the MAC address app by app.",
        "br": "A funcionalidade de verificação de MAC é para verificar se o endereço MAC está registrado no sistema ou se está ativado ou não. O revendedor pode verificar o endereço MAC aplicativo por aplicativo.",
        "ar": "تحقق من أن الوظيفة MAC للتحقق من تم تسجيل عنوان MAC في النظام أم لا. يمكن للموزع التحقق من عنوان MAC تطبيقًا بتطبيق."
    },
    "Credits Count": {
        "en": "Credits Count",
        "br": "Contagem de créditos",
        "ar": "عدد الاعتمادات"
    },
    "Description": {
        "en": "Description",
        "br": "Descrição",
        "ar": "وصف",
    },
    "Date": {
        "en": "Date",
        "br": "Data",
        "ar": "تاريخ",
    },
    "In the Credit history is seen the given and taken credits by a reseller to another subreseller.": {
        "en": "In the Credit history is seen the given and taken credits by a reseller to another subreseller.",
        "br": "No histórico de crédito, são vistos os créditos dados e recebidos por um revendedor para outro subrevendedor.",
        "ar": "في تاريخ الائتمان يتم رؤية الاعتمادات المعطاة والمأخوذة من قبل الموزع لموزع آخر."
    },
    "Check MAC functional is for checking the MAC address is registered in the system or is activated or not. Reseller can check the MAC address app by app.": {
        "en": "Check MAC functional is for checking the MAC address is registered in the system or is activated or not. Reseller can check the MAC address app by app.",
        "br": "A funcionalidade de verificação de MAC é para verificar se o endereço MAC está registrado no sistema ou se está ativado ou não. O revendedor pode verificar o endereço MAC aplicativo por aplicativo.",
        "ar": "تحقق من أن الوظيفة MAC للتحقق من تم تسجيل عنوان MAC في النظام أم لا. يمكن للموزع التحقق من عنوان MAC تطبيقًا بتطبيق."
    },
    "OK": {
        "en": "OK",
        "br": "OK",
        "ar": "حسنا"
    },
    "In the Credit history is seen the given and taken credits by a reseller to another subreseller.": {
        "en": "In the Credit history is seen the given and taken credits by a reseller to another subreseller.",
        "br": "No histórico de crédito, são vistos os créditos dados e recebidos por um revendedor para outro subrevendedor.",
        "ar": "في تاريخ الائتمان يتم رؤية الاعتمادات المعطاة والمأخوذة من قبل الموزع لموزع آخر."
    },
    "Are seen the credits given or taken from admin to the reseller.": {
        "en": "Are seen the credits given or taken from admin to the reseller.",
        "br": "São vistos os créditos dados ou recebidos do administrador para o revendedor.",
        "ar": "تظهر الاعتمادات المعطاة أو المأخوذة من المشرف إلى الموزع."
    },
    "In the Credit counts is seen left credits after every action and the action is described in the description field with date of action.": {
        "en": "In the Credit counts is seen left credits after every action and the action is described in the description field with date of action.",
        "br": "No Credit counts, são vistos os créditos restantes após cada ação e a ação é descrita no campo de descrição com a data da ação.",
        "ar": "في عدد الاعتمادات يتم رؤية الاعتمادات المتبقية بعد كل إجراء ويتم وصف الإجراء في حقل الوصف مع تاريخ الإجراء."
    },
    "Download": { "en": "Download", "br": "Baixar", "ar": "تحميل" },
    "Apps & Links": { "en": "Apps & Links", "br": "Aplicativos e Links", "ar": "التطبيقات والروابط" },
    "Guide": { "en": "Guide", "br": "Guia", "ar": "دليل" },
    "Samsung App": { "en": "Samsung App", "br": "Aplicativo Samsung", "ar": "تطبيق Samsung" },
    "Unzip uzerwidget.zip file": { "en": "Unzip uzerwidget.zip file", "br": "Descompacte o arquivo uzerwidget.zip", "ar": "فك ضغط ملف uzerwidget.zip" },
    "Take a clear USB stick": { "en": "Take a clear USB stick", "br": "Pegue um pen drive limpo", "ar": "خذ قلم ذاكرة USB واضح" },
    "Upload userwidget folder to the USB stick": { "en": "Upload userwidget folder to the USB stick", "br": "Carregue a pasta userwidget no pen drive", "ar": "قم بتحميل مجلد userwidget على قرص USB" },
    "On Tizen tv go to the apps menu (make sure you are connected to the internet)": { "en": "On Tizen tv go to the apps menu (make sure you are connected to the internet)", "br": "Na TV Tizen, vá para o menu de aplicativos (certifique-se de estar conectado à Internet)", "ar": "على تلفزيون Tizen انتقل إلى قائمة التطبيقات (تأكد من أنك متصل بالإنترنت)" },
    "If you already have installed this app on your tv make sure you have completely deleted it before installing the new one": { "en": "If you already have installed this app on your tv make sure you have completely deleted it before installing the new one", "br": "Se você já instalou este aplicativo na sua TV, certifique-se de que o excluiu completamente antes de instalar o novo", "ar": "إذا كنت قد قمت بتثبيت هذا التطبيق بالفعل على تلفزيونك ، تأكد من أنك قمت بحذفه تمامًا قبل تثبيت الجديد" },
    "Plug USB to Samsung Tizen tv (app must be installed automated)": { "en": "Plug USB to Samsung Tizen tv (app must be installed automated)", "br": "Conecte o USB à TV Samsung Tizen (o aplicativo deve ser instalado automaticamente)", "ar": "قم بتوصيل USB بتلفزيون Samsung Tizen (يجب تثبيت التطبيق تلقائيًا)" },
    "App Installation Guide": { "en": "App Installation Guide", "br": "Guia de Instalação do Aplicativo", "ar": "دليل تثبيت التطبيق" },
    "Go to your TV content store": { "en": "Go to your TV content store", "br": "Vá para a loja de conteúdo da sua TV", "ar": "انتقل إلى متجر محتوى التلفزيون الخاص بك" },
    "Search for app": { "en": "Search for app", "br": "Procure o aplicativo", "ar": "ابحث عن التطبيق" },
    "Install the app": { "en": "Install the app", "br": "Instale o aplicativo", "ar": "قم بتثبيت التطبيق" },
    "Accept Privacy policy": { "en": "Accept Privacy policy", "br": "Aceitar política de privacidade", "ar": "قبول سياسة الخصوصية" },
    "You have 7 days FREE TRIAL with demo content": { "en": "You have 7 days FREE TRIAL with demo content", "br": "Você tem 7 dias de TESTE GRATUITO com conteúdo de demonstração", "ar": "لديك 7 أيام تجربة مجانية مع محتوى تجريبي" },
    "After free trial you will be redirected to the activation page": { "en": "After free trial you will be redirected to the activation page", "br": "Após o teste gratuito, você será redirecionado para a página de ativação", "ar": "بعد التجربة المجانية ، سيتم توجيهك إلى صفحة التنشيط" },
    "Android TV & Box": { "en": "Android TV & Box", "br": "Android TV & Box", "ar": "Android TV & Box" },
    "Enable Unknown Sources: In Settings, go to Security & Restrictions  Enable Install unknown apps": {
        "en": "Enable Unknown Sources: In Settings, go to Security & Restrictions  Enable Install unknown apps",
        "br": "Ativar Fontes Desconhecidas: Em Configurações, vá para Segurança e Restrições Ativar Instalar aplicativos desconhecidos",
        "ar": "تمكين المصادر غير المعروفة: في الإعدادات ، انتقل إلى الأمان والقيود قم بتمكين تثبيت التطبيقات غير المعروفة"
    },
    "Download and Install: Download the APK Transfer to Android TV and install": { "en": "Download and Install: Download the APK Transfer to Android TV and install", "br": "Baixar e Instalar: Baixar o APK Transferir para Android TV e instalar", "ar": "تنزيل وتثبيت: تنزيل APK نقله إلى Android TV وتثبيته" },
    "Open File Explorer and Install: Locate APK in the file explorer Follow on-screen instructions to install": {
        "en": "Open File Explorer and Install: Locate APK in the file explorer Follow on-screen instructions to install",
        "br": "Abra o Explorador de Arquivos e Instale: Localize o APK no explorador de arquivos Siga as instruções na tela para instalar",
        "ar": "افتح مستكشف الملفات وقم بالتثبيت: حدد APK في مستكشف الملفات اتبع التعليمات على الشاشة لتثبيته"
    },
    "Launch Installd App: Find the app in the app drawer or home screen.": { "en": "Launch Installd App: Find the app in the app drawer or home screen.", "br": "Inicie o aplicativo instalado: encontre o aplicativo na gaveta de aplicativos ou na tela inicial.", "ar": "تشغيل التطبيق المثبت: العثور على التطبيق في درج التطبيقات أو الشاشة الرئيسية." },
    "Refresh": { "en": "Refresh", "br": "Atualizar", "ar": "تحديث" },
    "Account": { "en": "Account", "br": "Conta", "ar": "حساب" },
    "Update Profile": { "en": "Update Profile", "br": "Atualizar Perfil", "ar": "تحديث الملف الشخصي" },
    "Multi Player Admin": { "en": "Multi Player Admin", "br": "Administração de vários jogadores", "ar": "مدير اللاعبين المتعددين" },
    "Multi Player Reseller": { "en": "Multi Player Reseller", "br": "Revendedor de vários jogadores", "ar": "موزع اللاعبين المتعددين" },
    "Do you want to logout?": { "en": "Do you want to logout?", "br": "Você deseja sair?", "ar": "هل تريد تسجيل الخروج؟" },
    "App Count": { "en": "App Count", "br": "Contagem de aplicativos", "ar": "عدد التطبيقات" },
    "Total Statistics": { "en": "Total Statistics", "br": "Estatísticas totais", "ar": "الإحصاءات الكلية" },
    "Free DNS": { "en": "Free DNS", "br": "DNS gratuito", "ar": "DNS مجاني" },
    "Statistics": { "en": "Statistics", "br": "Estatísticas", "ar": "إحصائيات" },
    "Free DNS Total Statistics": { "en": "Free DNS Total Statistics", "br": "Estatísticas totais de DNS gratuito", "ar": "إحصائيات DNS المجانية الكلية" },
    "Total": { "en": "Total", "br": "Total", "ar": "مجموع" },
    "Free DNS Statistic": { "en": "Free DNS Statistic", "br": "Estatística de DNS gratuito", "ar": "إحصائيات DNS المجانية" },
    "Notifications": { "en": "Notifications", "br": "Notificações", "ar": "إشعارات" },
}






export default TEXTS;
