import IMAGES from '../../config/images';

import LoginForm from './LoginForm';
import styles from "./login.module.scss";

export default function Login() {
    return (
        <div className={styles['container']}>
            <div className={styles['bg-image']}></div>
            <div className={styles['form-section']}>
                <LoginForm />
            </div>
            <div className={styles['platforms']}></div>
        </div>
    )
}
