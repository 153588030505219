import React from 'react';

import { Dropdown, Flex } from 'antd';

import { EllipsisOutlined } from '@ant-design/icons';

export default function ActionDropdown({ items }) {

    return (<div onClick={(e) => e.stopPropagation()}>
        <Dropdown
            trigger={['click']}
            menu={{
                items
            }}
            placement="bottom"
        >
            <EllipsisOutlined style={{ cursor: "pointer" }} onClick={(e) => e.stopPropagation()} />
        </Dropdown>
    </div>
    )
}
