import React from 'react'

import { CalendarOutlined } from '@ant-design/icons';

import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export default function dateFilterDropdown(setDate) {
    return {
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                size="small"
            >
                <RangePicker onChange={(e, dateString) => setDate(dateString)} />
            </div>
        ),
        filterIcon: (filtered) => (
            <CalendarOutlined />
        ),
    }

}
