import InfoModal from "../../../components/InfoModal"
import Text from "../../../components/Text"

export default function PageDescription({ open, setOpen }) {
    return (
        <InfoModal
            title={<Text>Devices</Text>}
            open={open}
            handleCancel={() => setOpen(false)}
            text={
                <div>
                    <Text>Devices list MAC address and search also is done by MAC address.</Text><br />
                    <Text>Search is done by MAC address and activated by email.</Text><br />
                    <Text>Activated by shows the email who activated the device.</Text><br />
                    <Text>App name gives the name on what app the device has been activated</Text><br />
                    <Text>Used credits are the count of credits that are used for the device activation.</Text><br />
                    <Text>Activated and Expire Dates shows the created and expired date of the device.</Text><br />
                </div>
            }
        />
    )
}
