import InfoModal from '../../../components/InfoModal';

import { Alert } from 'antd';

import Text from '../../../components/Text';

export default function PageDescription({ open, setOpen }) {
    return (
        <InfoModal
            title={<Text>Activation</Text>}
            open={open}
            handleCancel={() => setOpen(false)}
            text={
                <div>
                    <Text>Reseller can activate device by choosing from available apps entering MAC address and choosing the package and add a nite if needed.</Text><br />
                    <Text>If MAC address is active yearly and the reseller activates it it will lenghten the expire date of the device.</Text><br />
                    If the Device is activated by lifetime it will not let the reseller to activate the device again.
                    <br /><br />
                    <Alert type='warning' message={<>
                        <strong style={{ color: "red" }}><Text>NOTE</Text>: </strong><Text>If the MAC address is not registered in the system it will not be activated.The user will need to donwload the app before the activation of the reseller.</Text>
                    </>} />
                </div>
            }
        />
    )
}
