import React from 'react'

import { Divider } from "antd";

import { InfoCircleOutlined } from '@ant-design/icons';

export default function PageTemplate({ title, setInfo, children }) {
    return (
        <>
            {setInfo && <InfoCircleOutlined className="info-icon" onClick={() => setInfo(true)} />}
            <Divider orientation="left" >{title}</Divider>
            {children}
        </>
    )
}
