import InfoModal from '../../../components/InfoModal';

import Text from '../../../components/Text';

import styles from "../index.module.scss"

export default function PageDescription({ open, setOpen }) {
    return (
        <InfoModal
            open={open}
            handleCancel={() => setOpen(false)}
            title={<Text>Guide</Text>}
            text={<>
                <h2 className={styles['modal-title']}><Text>Samsung App</Text></h2>
                <ul className={styles['list']}>
                    <li><Text>Unzip uzerwidget.zip file</Text></li>
                    <li><Text>Take a clear USB stick</Text></li>
                    <li><Text>Upload userwidget folder to the USB stick</Text></li>
                    <li><Text>On Tizen tv go to the apps menu (make sure you are
                        connected to the internet)</Text></li>
                    <li><Text>If you already have installed this app on your tv make sure
                        you have completely deleted it before installing the new one</Text></li>
                    <li><Text>Plug USB to Samsung Tizen tv (app must be installed
                        automated)</Text></li>
                </ul>

                <h2 className={styles['modal-title']}><Text>App Installation Guide</Text></h2>
                <ol className={styles['list']}>
                    <li><Text>Go to your TV content store</Text></li>
                    <li><Text>Search for app</Text></li>
                    <li><Text>Install the app</Text></li>
                    <li><Text>Accept Privacy policy</Text></li>
                    <li><Text>You have 7 days FREE TRIAL with demo content</Text></li>
                    <li><Text>After free trial you will be redirected to the activation page</Text></li>
                </ol>

                <h2 className={styles['modal-title']}><Text>Android TV & Box</Text></h2>
                <ul className={styles['list']}>
                    <li><Text>Enable Unknown Sources: In Settings, go to "Security &
                        Restrictions  "Enable" Install unknown apps"</Text></li>
                    <li><Text>Download and Install: Download the APK Transfer to
                        Android TV and install</Text></li>
                    <li><Text>Open File Explorer and Install: Locate APK in the file
                        explorer Follow on-screen instructions to install</Text></li>
                    <li><Text>Launch Installd App: Find the app in the app drawer or
                        home screen.</Text></li>
                </ul>
            </>}
        />
    )
}
