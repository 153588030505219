import React from 'react'
import { DatePicker } from 'antd';

import { CalendarOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

export default function filterDropdown(setDate) {
    return ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
                size="small"
            >
                <RangePicker
                    onKeyDown={(e) => {
                        if (!((e.keyCode >= 48 && e.keyCode <= 57) || // numbers
                            (e.keyCode >= 96 && e.keyCode <= 105) || // numpad
                            [8, 9, 37, 39, 46].includes(e.keyCode))) { // backspace, delete, left, right
                            e.preventDefault();
                        }
                    }}
                    onChange={(e, dateString) => {
                        setDate(dateString)
                    }} />
            </div >
        ),
        filterIcon: (filtered) => (
            <CalendarOutlined />
        ),
    })
}
