import { useEffect, useState } from "react";

import { Button, Drawer, Form, Flex, Input, Checkbox } from "antd";

import { REQUESTS } from "../../../api/requests";

import Message from "../../../components/Message";
import Text from "../../../components/Text";
import TEXTS from "../../../config/resellerText";
import useStore from "../../../hooks/use-store";

export default function PlaylistActionsDrawer({ current, playlist, open, onClose, getPlaylists, actionType }) {
    const [form] = Form.useForm();

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const [lang] = useStore("language");


    const onFinish = (values) => {
        const { app_id, mac } = current;
        const { hide_url, name, url } = values;

        const body = {
            hide_url,
            name,
            url,
            app_id,
            mac
        };

        setMessage({
            type: false,
            text: ""
        })

        if (actionType === "edit") {

            body.playlist_id = playlist.id;

            REQUESTS.PLAYLIST.EDIT(body, (data) => {
                getPlaylists();
                onClose();
            }, (error) => {
                setMessage({
                    type: false,
                    text: error
                })
            });
        } else {
            REQUESTS.PLAYLIST.CREATE(body, (data) => {
                getPlaylists();
                onClose();
            }, (error) => {
                setMessage({
                    type: false,
                    text: error
                })
            });
        }
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();

            setMessage({
                type: false,
                text: ""
            })
        } else {
            if (actionType === "edit") {
                form.setFieldsValue({
                    name: playlist?.name,
                    url: playlist?.url,
                    hide_url: playlist?.hide_url
                })
            }
        }
    }, [open])

    return (
        <Drawer
            title={actionType === "add" ? <Text>Add Playlist</Text> : <Text>Edit Playlist</Text>}
            onClose={onClose}
            open={open}
            destroyOnClose={true}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    app_id: current?.app_id,
                    mac: current?.mac,
                    url: "",
                    hide_url: true,
                    name: ""

                }}
            >
                <Form.Item
                    label={<Text>Name</Text>}
                    name="name"
                    rules={[{
                        required: true,
                        message: <Text>Name is required!</Text>
                    }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="URL"
                    name="url"
                    rules={[
                        {
                            required: true,
                            message: <Text>URL is required!</Text>
                        },
                        {
                            validator: (_, value) => {
                                if (!value || /^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(TEXTS["Please enter a valid URL!"][lang]));
                            }
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="hide_url"
                    valuePropName="checked"
                >
                    <Checkbox><Text>Hide URL</Text></Checkbox>
                </Form.Item>

                <Flex justify='center'>
                    <Message message={message} />
                </Flex>
                <Form.Item>
                    <div className="form-btn-container">
                        <Button
                            htmlType="submit"
                            type="primary">
                            <Text>Save</Text>
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
