import InfoModal from '../../../components/InfoModal';

import Text from '../../../components/Text';

export default function PageDescription({ open, setOpen }) {
    return (
        <InfoModal
            title={<Text>Check Mac</Text>}
            open={open}
            handleCancel={() => setOpen(false)}
            text={<Text>Check MAC functional is for checking the MAC address is registered in the system or is activated or not. Reseller can check the MAC address app by app.</Text>}
        />
    )
}
