
import { useEffect, useState } from 'react';

import { Drawer, Form, Input, Button, Flex, Alert } from 'antd';

import { REQUESTS } from '../../../api/requests';
import Message from '../../../components/Message';
import useUpdateProfile from '../../../hooks/use-update-profile';
import Text from '../../../components/Text';

export default function CreditsDrawer({ onClose, creditUpdate, current, getSubResellers }) {
    const [form] = Form.useForm();

    const [profile, refreshProfile] = useUpdateProfile();

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        const { credit } = values;

        setMessage({
            type: false,
            text: ""
        })

        setLoading(true)

        const body = {
            id: current.id,
            credit
        }

        if (creditUpdate === "Add Credit") {
            REQUESTS.CREDITS.ADD(body, (data) => {
                setLoading(false)
                getSubResellers();
                refreshProfile()
                onClose();
            }, (err) => {
                setLoading(false)
            })
        } else {
            REQUESTS.CREDITS.TAKE(body, (data) => {
                setLoading(false)
                getSubResellers();
                onClose();
                refreshProfile()
            }, (err) => {
                setLoading(false)
                setMessage({
                    type: false,
                    text: err
                })
            })
        }
    }

    const validateCredits = (_, value) => {
        if (value < 0) {
            return Promise.reject(<Text>Credit value must be greater than or equal to 0!</Text>);
        } else if (creditUpdate === "Add Credit" && value > profile?.credits) {
            return Promise.reject(<Text>Credit value must be less than or equal to your available credits!</Text>);
        } else if (creditUpdate === "Take Credit" && value > current?.credits) {
            return Promise.reject(<Text>Credit value must be less than or equal to sub reseller credits!</Text>);
        }

        return Promise.resolve();
    };

    useEffect(() => {
        if (!creditUpdate) {
            form.resetFields();
            setMessage({
                type: false,
                text: ""
            })
        }
    }, [creditUpdate])

    return (
        <Drawer
            title={<Text>{creditUpdate}</Text>}
            onClose={onClose}
            open={creditUpdate}>

            <Alert message={
                creditUpdate == "Add Credit" ?
                    <>
                        <Text>You have</Text> {profile?.credits} <Text>credit points balance.</Text> <Text>Please allot credit point lesser than your current credit!</Text>
                    </>
                    :
                    <Text>
                        <Text>Reseller have</Text> {current?.credits} <Text>credit points balance.</Text> <Text>Please deduct credit point lesser or equal to current credit!</Text>
                    </Text>
            }
                type="info"
                showIcon
                style={{ marginBottom: 20 }}
            />

            <Form
                form={form}
                name="basic"
                style={{
                    maxWidth: 600,
                }}
                autoComplete="off"
                layout='vertical'
                onFinish={onFinish}
                initialValues={{
                    credit: ""
                }}
            >
                <Form.Item
                    name="credit"
                    label={<Text>Credits</Text>}
                    rules={[
                        {
                            required: true,
                            message: <Text>Credits is required!</Text>
                        },
                        {
                            validator: validateCredits,
                        },
                    ]}>
                    <Input
                        type='number'
                        min={0}
                    />
                </Form.Item>
                <Flex justify='center'>
                    <Message message={message} />
                </Flex>
                <Form.Item >
                    <Flex justify="center">
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                        >
                            <Text>Save</Text>
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
