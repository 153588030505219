import { Input, Form } from "antd";

export default function ValidateMacAddress({
  value = "",
  setValue,
  onChange,
  ...props
}) {

  const generateMacFormat = (e) => {

    var mac= e.target.value.replace(/[^0-9a-z]/gi, "");
    var result = "";

    for (var i = 0; i < mac.length; i++) {
      if (i % 2 === 0 && i !== 0) {
        result += ":";
      }
      result += mac[i];
    }
    setValue(result.substring(0, 17));

    if (onChange) {
      onChange(e); // Call the onChange prop passed from the parent component
    }
  };

  return <Input
    value={value}
    onChange={generateMacFormat}
    {...props}
    maxLength={17}
  />
}
