import React, { useEffect, useState } from 'react';

import { Chart } from "react-google-charts";

import { REQUESTS } from '../../../api/requests';

import country from "../../../assets/country.json"

import Text from '../../../components/Text';
import styles from "../index.module.scss";

export default function DevicesByCountryChart() {
    const [data, setData] = useState([["Country", "Devices"]])


    const getData = () => {
        REQUESTS.DEVICES_BY_COUNTRY_STATISTICS((data) => {
            let newData = [];

            for (let i = 0; i < data.length; i++) {
                const countryName = country.find((c) => c.code === data[i].country);
                if (countryName) newData.push([countryName.name, data[i].count]);
            }

            setData([["Country", "Devices"], ...newData])

        }, (err) => { })
    }

    useEffect(() => {
        getData()
    }, [])

    return (<div className={styles['chart']} style={{ marginTop: 20 }}>
        <h2 className={styles["title"]}><Text>Devices By Country</Text></h2>
        <Chart
            chartEvents={[
                {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = data[selection[0].row + 1];
                    },
                },
            ]}
            chartType="GeoChart"
            width="100%"
            height="400px"
            data={data}
        />
    </div>
    )
}
