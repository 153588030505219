import { REQUESTS } from '../api/requests';

import useStore from './use-store';

export default function useUpdateProfile() {

    const [profile, setProfile] = useStore("profile");

    const refreshProfile = () => {
        REQUESTS.PROFILE.GET((data) => {
            setProfile({ type: "profile/setProfile", payload: data });
        });
    };

    return [profile, refreshProfile];
}
